export const cabeceraStyle=(esComputadora) => ({
    gridArea: "1/1/2/21",
    textAlign:"center",
    fontWeight: '500',
    fontSize: esComputadora ? '2vw' : '3vh',
    padding: '0px',
    lineHeight: '0.9',
    display: "grid",
    //display: "inline-block",
    fontSize: "2em",
    color: "#505050",
    //marginBottom: "10vw"
  });
export const contCrearPersonaStyle=(esComputadora) => ({
    marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "repeat(20,1fr)",
    gridTemplateRows: "repeat(22,1fr)",
    gridArea: esComputadora ? "2/1/21/11":"2/1/21/21",
    backgroundColor: "#EEEEEE",
    //background: esComputadora ? "radial-gradient(circle at 57.52% 55.27%, #fac44d 0, #f2b53c 50%, #eaa62b 100%)":"",
    border: esComputadora ? "1px solid #ccc":"1px solid #ccc",
    borderRadius: esComputadora ? "10px":"10px",
    boxShadow: esComputadora ? '2px 2px 4px rgba(0, 0, 0, 0.2)':"",
    padding: esComputadora ? "30px":"",
    boxSizing: "border-box",
    width: "100%",
    height: "93%",
    overflow: "auto",
});
export const nombresStyle=(esComputadora) => ({
    gridArea: "1/1/2/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const apePaternoStyle=(esComputadora) => ({
    gridArea: "2/1/3/11",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const apeMaternoStyle=(esComputadora) => ({
    gridArea: "2/11/3/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const codDocumentoStyle=(esComputadora) => ({
    display: "flex",
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center", /* Centra verticalmente */
    marginLeft: "5px",
    
    gridArea: "3/1/4/11",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const numeroDocumentoStyle=(esComputadora) => ({
    gridArea: "3/11/4/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});

export const fecNacStyle=(esComputadora) => ({
    gridArea: "5/1/6/11",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const numCelularStyle=(esComputadora) => ({
    gridArea: "5/11/6/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const codDepartamentoStyle=(esComputadora) => ({
    display: "flex",
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center", /* Centra verticalmente */
    marginLeft: "5px",
    gridArea: "7/1/8/11",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const codProvinciaStyle=(esComputadora) => ({
    display: "flex",
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center", /* Centra verticalmente */
    marginLeft: "5px",
    gridArea: "7/11/8/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const codDistritoStyle=(esComputadora) => ({
    display: "flex",
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center", /* Centra verticalmente */
    marginLeft: "5px",
    marginTop: "5px",
    gridArea: "9/1/10/11",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const direccionStyle=(esComputadora) => ({
    //display: "flex",
    //justifyContent: "center", /* Centra horizontalmente */
    //alignItems: "center", /* Centra verticalmente */
    gridArea: "11/1/12/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: esComputadora ? "blue":"red",
});
export const correoElectronicoStyle=(esComputadora) => ({
    gridArea: "13/1/14/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const codigoRolStyle=(esComputadora) => ({
    display: "flex",
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center", /* Centra verticalmente */
    marginLeft: "5px",
    gridArea: "15/1/16/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const codDistribuidorStyle=(esComputadora, rolSelec) => {
    return {
    display: rolSelec==='ADIS01' ? "flex":"none",
    marginTop: "5px",
    //display: "",
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center", /* Centra verticalmente */
    marginLeft: "5px",
    gridArea: "17/1/18/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
}};
export const pagoCSS=(esComputadora,rolSelec)=>({
    background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 100%)",
    gridArea: rolSelec==='ADIS01' ? "18/1/19/21":"17/1/18/21",
    marginTop: "15px",
    marginLeft: "5px",
    padding: "5px 0px 5px 0px",
    borderTop: "1px #ababab solid",
    borderBottom: "1px #ababab solid",
    display: (rolSelec === 'ADIS01' || rolSelec === 'DIST01') ? 'none':'flex',
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center", /* Centra verticalmente */
    fontWeight: "700",
    fontFamily: "Helvetica, sans-serif"
})
export const pagoValorCSS=(esComputadora,rolSelec)=>({
    //backgroundColor:"green", 
    display: (rolSelec === 'ADIS01' || rolSelec === 'DIST01') ? 'none':'flex',
    gridArea: rolSelec==='ADIS01' ? "20/1/21/21":"19/1/20/21",
    marginTop: "5px",
    marginLeft: "5px",
    padding: "5px 0px 5px 0px",
    //display: "flex",
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center", /* Centra verticalmente */  
})
export const botonEnviarStyle=(esComputadora,rolSelec) => ({
    marginTop: "5px",
    //display: "",
    justifyContent: "center", /* Centra horizontalmente */
    alignItems: "center", /* Centra verticalmente */
    marginLeft: "5px",
    gridArea: rolSelec==='ADIS01' ? "21/1/22/21":"20/1/21/21",
    width: esComputadora ? "97%":"97%",
    //backgroundColor: "red",
});
export const modalStyle={
    position: "absolute",
    left: "0px",
    top: "0px",
    width: "100vw",
    height: "100vh",
    backgroundColor: hexToRGBA("#C1C1C1",0.8),
    zIndex: "20", 
    boxSizing: "border-box"
}
export const contContenidoModalStyle=(esComputadora)=>({
    position: "relative",
    width: esComputadora ? "50vw":"90vw",
    height: esComputadora ? "50vh":"30vh",
    left: esComputadora ? "25vw":"5vw",
    boxSizing: "border-box",
    top: esComputadora ? "25vh":"35vh",
  })
export const contenidoModalStyle=(esComputadora)=>({
    display: 'grid',
    gridTemplateRows: 'repeat(20, 1fr)',
    gridTemplateColumns: 'repeat(20, 1fr)',
    width: esComputadora ? "50vw":"90vw",
    height: esComputadora ? "50vh":"30vh",
    overflow: "auto",
    backgroundColor: "white",
    boxSizing: "border-box",
    borderRadius: "10px",
    padding: "10px"
})

export const cerrarStyle=(esComputadora)=>({
    display: "flex",
    justifyContent: "flex-end", /* Alinea los elementos al final del contenedor (derecha) */
    alignItems: "flex-start", 
    gridArea: "1/18/2/21",
})
export const exitoStyle=(esComputadora)=>({
    gridArea: "1/1/2/18",
    backgroundColor: "white",
    display: "inline",
    textAlign: "center"
})
export const respuestaStyle=()=>({
    gridArea: "2/1/21/21",
})
export const  labelStyle=()=>({
    //display: "inline-block",
    fontFamily: 'Courier New, Courier, monospace',     //backgroundColor: "orange",
    fontSize: "1em",
    color: "#737373",
    fontWeight: "700",
    marginRight: "5px",
  })
  export const  contenidoLabelStyle=()=>({
    display: "grid",
    //display: "inline-block",
    //fontFamily: 'Courier New, Courier, monospace',   //backgroundColor: "orange",
    fontSize: "1em",
    color: "#505050",
    fontWeight: "200",
    marginRight: "5px",
  })
export const contLabelStyle=(esComputadora)=>({
    display: "grid",
    gridTemplateColumns: "30% 70%",
    gridTemplateRows: "100%",
    padding: esComputadora ? "1vw":"",
})
function hexToRGBA (hex, opacidad) {
    hex = hex.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
  
    return `rgba(${r}, ${g}, ${b}, ${opacidad})`
  }