import React, { useState, useEffect, useRef } from 'react'
import * as estilos from './ModalVerPagoPedidoCSS'
import Boton from '../../../../Elementos/Boton'
import { Page, Text, View, Document, PDFViewer } from '@react-pdf/renderer'

import {
  hexToRGBA,
  formatearFecha2,
  capitalizeWords,
  formatearFecha3,
  formatearMonedaSoles,
  formatDateTime,
  pagosPedido,
  
} from '../../../../ApiSanMiguel/Api'

function ModalVerPagoPedido (datos) {
  //const [verPdf2, setVerPdf] = useState(datos.verpdf)
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rangoFechas, setrangoFechas] = useState(datos.rangoFechas)
  const [asistenciaDetalleData, setAsistenciaDetData] = useState(
    datos.asistenciaDetalleData
  )
  const [pagos, setPagos] = useState(null)
  const [noregistraPago, setNoRegistraPago] = useState(false)
  const totalPago = useRef(0)
  useEffect(() => {
    const ejecucion = async () => {
      const respuesta = await pagosPedido(datos.codigoPedidoEliminar)
      if (respuesta) {
        console.log(respuesta)
        if (respuesta.respuesta.pagos.length > 0)
          setPagos(respuesta.respuesta.pagos)
        else setNoRegistraPago(prevState => !prevState)
      }
    }
    ejecucion()
  }, [])

  return (
    <>
      {console.log(datos.esComputadora)}
      <div id='baseModal' style={estilos.baseModal(datos.esComputadora)}>
        {datos.mostrarModal && (
          <div
            id='contenedorModal'
            style={estilos.contenedorModal(datos.esComputadora)}
          >
            <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}>
              <Boton
                moderno='X'
                tipo='submit'
                color={hexToRGBA('#E12121', 1)}
                tonalidad='20'
                borde='5px 10px 5px'
                margenExterno={datos.esComputadora ? '0vw' : '0vw'}
                margenInterno={
                  datos.esComputadora ? '0.6vw 0.2vw' : '1vw 2vw 1vw 2vw'
                }
                fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
                width={datos.esComputadora ? '5vh' : '7vw'}
                height={datos.esComputadora ? '5vh' : '7vw'}
                estaProcesando={estaProcesando}
                onClick={datos.cerrarPdfModalClick}
                labelStyle={{
                  marginTop: datos.esComputadora ? '' : '30%',
                  fontSize: datos.esComputadora ? '2vh' : '3vw'
                }}
              />
            </div>
            <div style={estilos.tituloCSS(datos.esComputadora)}>
              Registro de Pagos
            </div>
            <div
              id='contenidoModal'
              style={estilos.contenidoModal(datos.esComputadora)}
            >
              <table style={{...estilos.tablaCSS(datos.esComputadora),margin: "0px"}}>
                <thead>
                  <tr>
                    <th
                      colSpan='2'
                      style={{
                        ...estilos.thStyle(datos.esComputadora),
                        textAlign: 'right',
                        border: 'none'
                      }}
                    >
                      Nro. Pedido:
                    </th>
                    <td
                      style={{
                        ...estilos.thStyle(datos.esComputadora),
                        textAlign: 'left',
                        border: 'none'
                      }}
                    >
                      {String(datos.codigoPedidoEliminar).padStart(8, '0')}
                    </td>
                  </tr>
                  <tr>
                    <th
                      colSpan='2'
                      style={{
                        ...estilos.thStyle(datos.esComputadora),
                        textAlign: 'right',
                        border: 'none'
                      }}
                    >
                      Persona Solicitante:
                    </th>
                    <td
                      style={{
                        ...estilos.thStyle(datos.esComputadora),
                        textAlign: 'left',
                        border: 'none'
                      }}
                    >
                      {capitalizeWords(datos.nombreUsuarioPedido)}
                    </td>
                  </tr>
                </thead>
              </table>
              <table style={estilos.tablaCSS(datos.esComputadora)}>
                <thead>
                  <tr>
                    <th style={estilos.thStyle(datos.esComputadora)}>Monto</th>
                    <th style={estilos.thStyle(datos.esComputadora)}>
                      Fecha de Pago
                    </th>
                    <th style={estilos.thStyle(datos.esComputadora)}>
                      Fecha de Registro
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pagos !== null &&
                    pagos.map(pago => {
                      totalPago.current += pago.monto !== null && pago.monto

                      return (
                        <tr key={pago.id}>
                          <td style={estilos.tdStyle(datos.esComputadora)}>
                            {pago.monto !== null
                              ? formatearMonedaSoles(pago.monto)
                              : ''}
                          </td>
                          <td style={estilos.tdStyle(datos.esComputadora)}>
                            {pago.fecha_pago !== null
                              ? formatearFecha2(pago.fecha_pago)
                              : ''}
                          </td>
                          <td style={estilos.tdStyle(datos.esComputadora)}>
                            {pago.fecha_registro !== null
                              ? formatDateTime(pago.fecha_registro)
                              : ''}
                          </td>
                        </tr>
                      )
                    })}
                  {pagos !== null && (
                    <tr>
                      <th colSpan='2' style={{textAlign: "right"}}>Total Pagado:</th>
                      <th style={{textAlign: "left"}}>{formatearMonedaSoles(totalPago.current)}</th>
                    </tr>
                  )}
                  {noregistraPago ? (
                    <tr>
                      <td
                        colSpan='3'
                        style={{ borderBottom: '1px solid black' }}
                      >
                        No Registra Pagos
                      </td>
                    </tr>
                  ) : pagos === null ? (
                    <tr>
                      <td
                        colSpan='3'
                        style={{ borderBottom: '1px solid black' }}
                      >
                        Cargando Pagos . . .
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ModalVerPagoPedido
