import React, { useState, useEffect } from 'react'
import Input from '../../../../../Elementos/Input'
import Boton from '../../../../../Elementos/Boton'

import Opciones from '../../../../../Elementos/Opciones'
import {
  listarRoles,
  listarTiposDocumentos,
  obtenerCookie,
  listarDepartamentos,
  listarProvincias,
  listarDistritos,
  listaDistribuidores,
  Persona,
  crearPersona,
  turnos,
  formasPago,
  registrarFormaPago
} from '../../../../../ApiSanMiguel/Api'

import {
  cabeceraStyle,
  contCrearPersonaStyle,
  nombresStyle,
  apePaternoStyle,
  apeMaternoStyle,
  numeroDocumentoStyle,
  fecNacStyle,
  codDocumentoStyle,
  codigoRolStyle,
  direccionStyle,
  correoElectronicoStyle,
  numCelularStyle,
  codDepartamentoStyle,
  codProvinciaStyle,
  codDistritoStyle,
  codDistribuidorStyle,
  botonEnviarStyle,
  contContenidoModalStyle,
  contenidoModalStyle,
  modalStyle,
  cerrarStyle,
  respuestaStyle,
  exitoStyle,
  labelStyle,
  contenidoLabelStyle,
  contLabelStyle,
  pagoCSS,
  pagoValorCSS
} from './CrearPersonaCSS'

function CrearPersona (datos) {
  const [documentos, setDocumento] = useState([])
  const [roles, setRoles] = useState([])
  const [departamentos, setDepartamentos] = useState([])
  const [provincias, setProvincias] = useState([])
  const [distritos, setDistritos] = useState([])
  const [distribuidores, setDistribuidores] = useState([])

  const [departamentoSelec, setDepartamentoSelec] = useState(0)
  const [provinciaSelec, setProvinciaSelec] = useState(0)
  const [distritoSelec, setDistritoSelec] = useState(0)
  const [rolSelec, setRolSelec] = useState(0)
  const [docSelec, setDocSelec] = useState(0)
  const [distSelec, setDistSelec] = useState(0)
  const [formaPagoSelec,setFormaPagoSelec] = useState(0)
  const [turnoSelect,setTurnoSelec] = useState(0)
  const [estaProcesando, setestaProcesando] = useState(false)
  //DATOS PERSONA
  const [nombres, setNombres] = useState('')
  const [apePaterno, setApePaterno] = useState('')
  const [apeMaterno, setApeMaterno] = useState('')
  const [nroDoc, setNroDoc] = useState('')
  const [nroCel, setNroCel] = useState('')
  const [direccion, setDireccion] = useState('')
  const [email, setEmail] = useState('')
  const [ubigeo, setUbigeo] = useState('')
  const [fecNac, setFecNac] = useState(null)
  const [montoPagar, setMontoPagar] = useState(0)
  //ENVIO
  const [envioForm, setEnvioForm] = useState(false)
  const [erroresModal, setErroresModal] = useState(null)
  const [modalDetalle, setModalDetalle] = useState(false)
  const [respModalDet, setRespModalDet] = useState(null)
  const [turnosData, setTurnosData] =useState([])
  const [formasPagoData, setFormasPagoData] = useState([])

  useEffect(() => {
    const listarOpciones = async () => {
      const respuesta = await listarRoles()
      const respuesta2 = await listarTiposDocumentos()
      const respuesta3 = await listarDepartamentos(604)
      const respuesta4 = await turnos()
      const respuesta5 = await formasPago()
      console.log(respuesta)
      if (respuesta && respuesta2 && respuesta3 && respuesta4 && respuesta5) {
        //console.log(respuesta)
        //console.log(respuesta2)
        //console.log(respuesta3)
        if (respuesta.SMListaErrores.length === 0) {
          setRoles(respuesta.roles)
        } else {
          setRoles(null)
        }
        if (respuesta2.SMListaErrores.length === 0) {
          setDocumento(respuesta2.tipoDocumento)
        } else {
          setDocumento(null)
        }
        if (respuesta3.SMListaErrores.length === 0) {
          setDepartamentos(respuesta3.Departamentos)
        } else {
          setDepartamentos(null)
        }
        if (respuesta4.SMListaErrores.length === 0) {
          setTurnosData(respuesta4.turnos)
          console.log(respuesta4.turnos)
        } else {
          setTurnosData(null)
        }
        if (respuesta5.SMListaErrores.length === 0) {
          setFormasPagoData(respuesta5.formasPago)
          console.log(respuesta5.formasPago)
        } else {
          setFormasPagoData(null)
        }
      }
    }
    listarOpciones()
  }, [])

  useEffect(() => {
    setProvincias([])
    setDistritos([])

    const listarOpciones = async () => {
      const respuesta = await listarProvincias(604, departamentoSelec)
      //console.log(respuesta)
      if (respuesta) {
        //console.log(respuesta)
        //console.log(respuesta2)
        //console.log(respuesta3)
        if (respuesta.SMListaErrores.length === 0) {
          setProvincias(respuesta.Provincias)
        } else {
          //setRoles(null)
        }
      }
    }
    listarOpciones()
  }, [departamentoSelec])

  useEffect(() => {
    setDistritos([])
    const listarOpciones = async () => {
      const respuesta = await listarDistritos(
        604,
        departamentoSelec,
        provinciaSelec
      )
      //console.log(respuesta)
      if (respuesta) {
        //console.log(respuesta)
        //console.log(respuesta2)
        //console.log(respuesta3)
        if (respuesta.SMListaErrores.length === 0) {
          setDistritos(respuesta.Distritos)
        } else {
          //setRoles(null)
        }
      }
    }
    listarOpciones()
  }, [provinciaSelec])

  const estiloVacio = entrada => ({
    border: !envioForm || entrada ? '1px solid #ccc' : '1px solid red',
    boxShadow:
      !envioForm || entrada ? 'inset 0 0 1px gray' : 'inset 0 0 5px red',
    
    })
  const cambiaCampoClick = event => {
    //console.log(event.currentTarget.id)

    switch (event.currentTarget.id) {
      case 'nombres':
        setNombres(event.currentTarget.value)
        break
      case 'apePaterno':
        setApePaterno(event.currentTarget.value)
        break
      case 'apeMaterno':
        setApeMaterno(event.currentTarget.value)
        break
      case 'numeroDocumento':
        setNroDoc(event.currentTarget.value)
        break
      case 'numCelular':
        setNroCel(event.currentTarget.value)
        break
      case 'direccion':
        setDireccion(event.currentTarget.value)
        break
      case 'correoElectronico':
        setEmail(event.currentTarget.value)
        break
      case 'montoPagar':
        console.log('montoPagar',event.currentTarget.value)
        setMontoPagar(event.currentTarget.value)
        break
      default:
        console.log('ninguno')
    }
  }
  const cambioFecNac = event => {
    setFecNac(event.currentTarget.value)
  }
  const documentoClick = event => {
    setDocSelec(parseInt(event))
  }
  const formaPagoClick = event =>{
    console.log(event)
    setFormaPagoSelec(event)
  }
  const turnoClick = event =>{
    console.log(parseInt(event))
    setTurnoSelec(parseInt(event))
  }
  const departamentosClick = event => {
    setDepartamentoSelec(parseInt(event))
  }
  const provinciasClick = event => {
    setProvinciaSelec(parseInt(event))
  }
  const distritosClick = event => {
    setDistritoSelec(parseInt(event))
  }
  useEffect(() => {
    const listarOpciones = async () => {
      try {
        setUbigeo(
          distSelec !== '' ||
            distSelec !== null ||
            departamentoSelec !== '' ||
            departamentoSelec !== null ||
            provinciaSelec !== '' ||
            provinciaSelec !== null
            ? departamentoSelec.toString().padStart(2, '0') +
                '' +
                provinciaSelec.toString().padStart(2, '0') +
                '' +
                distritoSelec.toString().padStart(2, '0')
            : ''
        )
      } catch (excepcion) {}
    }
    listarOpciones()
  }, [distritoSelec])

  const distribuidoresClick = event => {
    setDistSelec(event)
  }
  const rolClick = event => {
    console.log(event)
    setRolSelec(event)
  }

  useEffect(() => {
    const listarOpciones = async () => {
      const respuesta = await listaDistribuidores()
      if (respuesta) {
        if (respuesta.SMListaErrores.length === 0) {
          setDistribuidores(respuesta.Distribuidores)
        } else {
          //setRoles(null)
        }
      }
    }
    listarOpciones()
  }, [rolSelec])

  const enviarCrearPersonaClick = async event => {
    event.preventDefault()
    setEnvioForm(true)

    console.log(roles.filter((e)=>{return e.codigo === rolSelec}));
    let rolSelecId = roles.filter((e)=>{return e.codigo === rolSelec})[0]  ? roles.filter((e)=>{return e.codigo === rolSelec})[0].id : 0
    console.log(rolSelecId)
    if (
      nroDoc === null ||
      nroDoc === '' ||
      nombres === null ||
      nombres === '' ||
      apePaterno === null ||
      apePaterno === '' ||
      apeMaterno === null ||
      apeMaterno === '' ||
      docSelec === 0 ||
      docSelec === null ||
      docSelec === '' ||
      isNaN(docSelec) ||
      fecNac === null ||
      fecNac === '' ||
      ubigeo === null ||
      ubigeo === '' ||
      direccion === null ||
      direccion === '' ||
      //email === null || email=== '' ||
      nroCel === null ||
      nroCel === '' ||
      departamentoSelec === 0 ||
      departamentoSelec === '' ||
      departamentoSelec === null ||
      isNaN(departamentoSelec) ||
      provinciaSelec === 0 ||
      provinciaSelec === '' ||
      provinciaSelec === null ||
      isNaN(provinciaSelec) ||
      distritoSelec === 0 ||
      distritoSelec === '' ||
      distritoSelec === null ||
      isNaN(distritoSelec) ||
      rolSelecId === 0 ||
      rolSelecId === '' ||
      rolSelecId === null ||
      isNaN(rolSelecId) 
    ) {
        console.log(rolSelecId)
        
        setErroresModal([
            { descripcionError: 'Complete el/los campos marcados en rojo' }
          ])
        setModalDetalle(true)
    } else {
        //console.log(rolSelec)
        console.log(distritoSelec)
        if((rolSelec=='ADIS01'  && 
          (distSelec === '' || 
            distSelec === 0 || 
            distSelec ===null))

            || 
            ((rolSelec !== 'ADIS01' && rolSelec !=='DIST01') &&
            (montoPagar === 0 ||
            montoPagar === '' ||
            isNaN(montoPagar) ||
            montoPagar === null))
          ){
            setErroresModal([
                { descripcionError: 'Complete el/los campos marcados en rojo',  }
              ])
            setModalDetalle(true)
            return null
        }
        const persona = new Persona(
            nroDoc,
            nombres,
            apePaterno,
            apeMaterno,
            docSelec,
            fecNac,
            ubigeo,
            direccion,
            email, // correoElectronico
            nroCel,
            '',
            'recibo.jpg'
        )

        setestaProcesando(true)
        const respuesta = await crearPersona(1, 1, persona, rolSelecId, distSelec)
        if (respuesta) {
            if (respuesta.SMListaErrores.length == 0) {
            setModalDetalle(true)
            console.log(respuesta)
            setRespModalDet(respuesta)
            setestaProcesando(false)
            //se registran los pagos una vez que no tiene errores
            if(rolSelec !== 'DIST01' && rolSelec !== 'ADIS01'){
              const respuesta2 = await registrarFormaPago(
                respuesta.codigoUsuario,
                montoPagar,
                formaPagoSelec,
                turnoSelect
              )
              if(respuesta2){
                console.log(respuesta2)
              }
            }
            
            } else {
            setModalDetalle(true)
            setErroresModal(respuesta.SMListaErrores)
            setestaProcesando(false)
            }
        }
    }
  }
  const limpiarFormulario = async event => {
    const form = document.getElementById('formularioPersona')
    const nombres = document.getElementById('nombres')
    setNombres('')
    setApePaterno('')
    setApeMaterno('')
    setNroDoc('')
    setNroCel('')
    setDireccion('')
    setEmail('')
    setFecNac('')
    setFecNac(null)
    setDocSelec(null)
    setDepartamentoSelec(null)
    setProvinciaSelec(null)
    setDistritoSelec(null)
    setMontoPagar('')
    setFormaPagoSelec(null)
    setTurnoSelec(null)
    setRolSelec(null)
    form.reset()
    //event.target.form.reset();
  }

  const cerrarModalClick = () => {
    setModalDetalle(false)
    setErroresModal(null)
  }
  return (
    <>
      {modalDetalle ? (
        <div id='modalStyle' style={modalStyle}>
          <div
            id='contContenidoModalStyle'
            style={contContenidoModalStyle(datos.esComputadora)}
          >
            <div
              id='contenidoModalStyle'
              style={contenidoModalStyle(datos.esComputadora)}
            >
              <div id='cerrar' style={cerrarStyle()}>
                <Boton
                  moderno='X'
                  tipo='submit'
                  color={hexToRGBA('#F52424', 1)}
                  tonalidad='20'
                  borde='5px'
                  margenExterno='5px 5px 5px 5px'
                  margenInterno={
                    datos.esComputadora ? '10px' : '5px 2px 5px 2px'
                  }
                  fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
                  width={datos.esComputadora ? '40px' : '40px'}
                  height='40px'
                  estaProcesando={estaProcesando}
                  onClick={cerrarModalClick}
                  labelStyle={{
                    color: 'white',
                    fontWeight: '600',
                    fontSize: datos.esComputadora ? '1vw' : '1.5vh',
                    padding: datos.esComputadora ? '0.1vw' : '2vw'
                  }}
                />
              </div>
              {respModalDet !== null && respModalDet.exito === 'S' && <div style={exitoStyle(datos.esComputadora)}>
                      <span style={labelStyle()}>INFORMACIÓN </span>
                    </div>
                }
              <div style={respuestaStyle(datos.esComputadora)}>
                {erroresModal !== null ? (
                  erroresModal.map(i => {
                    return (
                      
                      <div key={0} style={contenidoLabelStyle()}>
                        {i.descripcionError}
                        
                      </div>
                    )
                  })
                ) : respModalDet !== null ? (
                  <>
                    
                    {respModalDet.nombre && <div style={contLabelStyle(datos.esComputadora)}>
                      <span style={labelStyle()}>Nombres: </span>
                      <span style={contenidoLabelStyle()}>{respModalDet.nombre}</span>
                    </div>
                    }
                    {respModalDet.codigoUsuario &&
                    <div style={contLabelStyle(datos.esComputadora)}>
                      <span style={labelStyle()}>Usuario: </span>
                      <span style={contenidoLabelStyle()}>{respModalDet.codigoUsuario}</span>
                    </div>
                    }
                    { respModalDet.contrasenia &&
                    <div style={contLabelStyle(datos.esComputadora)}>
                      <span style={labelStyle()}>Contraseña: </span>
                      <span style={contenidoLabelStyle()}>{respModalDet.contrasenia}</span>
                    </div>
                    }
                    {respModalDet.rol &&
                    <div style={contLabelStyle(datos.esComputadora)}>
                      <span style={labelStyle()}>Rol: </span>
                      <span style={contenidoLabelStyle()}>{respModalDet.rol}</span>
                    </div>
                    }
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div style={cabeceraStyle(datos.esComputadora)}>Registrar Persona</div>
      {false && (
        <div
          id='contCrearPersona'
          style={contCrearPersonaStyle(datos.esComputadora)}
        ></div>
      )}
      <form
        id='formularioPersona'
        style={contCrearPersonaStyle(datos.esComputadora)}
      >
        <div style={nombresStyle(datos.esComputadora)}>
          <Input
            id='nombres'
            moderno='Nombres'
            tipo='text'
            autoComplete
            estilos={estiloVacio(nombres)}
            onChange={cambiaCampoClick}
            valorInicial={nombres !== '' ? nombres : null}
          />
        </div>
        <div style={apePaternoStyle(datos.esComputadora)}>
          <Input
            id='apePaterno'
            moderno='Apellido Paterno'
            tipo='text'
            autoComplete
            estilos={estiloVacio(apePaterno)}
            onChange={cambiaCampoClick}
            valorInicial={apePaterno !== '' ? apePaterno : null}
          />
        </div>
        <div style={apeMaternoStyle(datos.esComputadora)}>
          <Input
            id='apeMaterno'
            moderno='Apellido Materno'
            tipo='text'
            autoComplete
            estilos={estiloVacio(apeMaterno)}
            onChange={cambiaCampoClick}
            valorInicial={apeMaterno !== '' ? apeMaterno : null}
          />
        </div>
        <div style={numeroDocumentoStyle(datos.esComputadora)}>
          <Input
            id='numeroDocumento'
            moderno='Nro. Documento'
            tipo='number'
            autoComplete
            estilos={estiloVacio(nroDoc)}
            onChange={cambiaCampoClick}
            valorInicial={nroDoc !== '' ? nroDoc : null}
            maxDigitos="8"
          />
        </div>
        <div style={fecNacStyle(datos.esComputadora)}>
          <Input
            id='fecNac'
            moderno='Fec. Nacimiento'
            tipo='date'
            autoComplete
            estilos={estiloVacio(fecNac)}
            //estiloLabel={estiloVacio}
            onChange={cambioFecNac}
            valorInicial={fecNac !== '' ? fecNac : null}
            //estiloLabel ={{ backgroundColor:"green"}}
          />
        </div>
        <div style={codDocumentoStyle(datos.esComputadora)}>
          <Opciones
            formPersona='entra'
            alto='55px'
            moderno='Tipo Documento'
            fontSize='0.9em'
            opciones={documentos !== null ? documentos : []}
            clave='id'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={documentoClick}
            estilos={estiloVacio(docSelec)}
          />
        </div>
        <div style={codigoRolStyle(datos.esComputadora)}>
          <Opciones
            formPersona='entra'
            alto='55px'
            moderno='Rol'
            fontSize='0.9em'
            opciones={roles !== null ? roles : []}
            clave='codigo'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={rolClick}
            estilos={estiloVacio(rolSelec)}
          />
        </div>
        <div style={codDepartamentoStyle(datos.esComputadora)}>
          <Opciones
            formPersona='entra'
            alto='55px'
            moderno='Departamento'
            fontSize='0.9em'
            opciones={departamentos !== null ? departamentos : []}
            clave='codDepartamento'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={departamentosClick}
            estilos={estiloVacio(departamentoSelec)}
          />
        </div>
        <div style={codProvinciaStyle(datos.esComputadora)}>
          <Opciones
            formPersona='entra'
            alto='55px'
            moderno='Provincia'
            fontSize='0.9em'
            opciones={provincias !== null ? provincias : []}
            clave='codProvincia'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={provinciasClick}
            estilos={estiloVacio(provinciaSelec)}
          />
        </div>
        <div style={codDistritoStyle(datos.esComputadora)}>
          <Opciones
            formPersona='entra'
            alto='55px'
            moderno='Distrito'
            fontSize='0.9em'
            opciones={distritos !== null ? distritos : []}
            clave='codDistrito'
            valor={['descripcion']}
            esComputadora={datos.esComputadora}
            onClick={distritosClick}
            estilos={estiloVacio(distritoSelec)}
          />
        </div>
        <div style={codDistribuidorStyle(datos.esComputadora, rolSelec)}>
          <Opciones
            formPersona='entra'
            alto='55px'
            moderno='Distribuidor'
            fontSize='0.9em'
            opciones={distribuidores !== null ? distribuidores : []}
            clave='codUsuario'
            valor={['Nombre', 'ApellidoPaterno']}
            esComputadora={datos.esComputadora}
            onClick={distribuidoresClick}
            estilos={estiloVacio(distSelec)}
          />
        </div>
        
        <div style={direccionStyle(datos.esComputadora)}>
          <Input
            id='direccion'
            moderno='Dirección'
            tipo='text'
            autoComplete
            estilos={estiloVacio(direccion)}
            onChange={cambiaCampoClick}
            valorInicial={direccion !== '' ? direccion : null}
          />
        </div>
        <div style={correoElectronicoStyle(datos.esComputadora)}>
          <Input
            id='correoElectronico'
            moderno='Email'
            tipo='text'
            autoComplete
            //estilos={estiloVacio(email)}
            onChange={cambiaCampoClick}
            valorInicial={email !== '' ? email : null}
          />
        </div>
        <div style={numCelularStyle(datos.esComputadora)}>
          <Input
            id='numCelular'
            moderno='Nro. Celular'
            tipo='number'
            autoComplete
            estilos={estiloVacio(nroCel)}
            onChange={cambiaCampoClick}
            valorInicial={nroCel !== '' ? nroCel : null}
            maxDigitos="9"
          />
        </div>
        <div style={pagoCSS(datos.esComputadora,rolSelec)}>
            Definir Pago
        </div>
        <div style={pagoValorCSS(datos.esComputadora,rolSelec)}>
          <Input
              id='montoPagar'
              moderno='Monto a pagar'
              tipo='number'
              autoComplete
              estilos={estiloVacio(montoPagar)}
              onChange={cambiaCampoClick}
              valorInicial={montoPagar !== '' ? montoPagar : null}
              maxDigitos="7"
            />
          <Opciones
              formPersona='entra'
              alto='55px'
              moderno='Forma de Pago'
              fontSize='0.9em'
              opciones={formasPagoData !== null ? formasPagoData : []}
              clave='codFormaPago'
              valor={['descripcion']}
              esComputadora={datos.esComputadora}
              onClick={formaPagoClick}
              estilos={{...estiloVacio(formaPagoSelec)
                        ,width: "97%"
                      }}
              
            />
            <Opciones
              formPersona='entra'
              alto='55px'
              moderno='Turno'
              fontSize='0.9em'
              opciones={turnosData !== null ? turnosData : []}
              clave='id'
              valor={['nombre']}
              esComputadora={datos.esComputadora}
              onClick={turnoClick}
              estilos={{...estiloVacio(turnoSelect)
                ,width: "97%"
              }}
            />
        </div>
        <div style={botonEnviarStyle(datos.esComputadora, rolSelec)}>
          <Boton
            moderno='Crear Persona'
            tipo='submit'
            color={hexToRGBA('#2D8B0B', 1)}
            tonalidad='20'
            borde='5px'
            margenExterno='5px 5px 5px 5px'
            margenInterno={datos.esComputadora ? '10px' : '15px 2px 15px 2px'}
            fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
            width={datos.esComputadora ? '100%' : '100%'}
            estaProcesando={estaProcesando}
            onClick={enviarCrearPersonaClick}
          />
          <Boton
            moderno='Limpiar Datos'
            tipo='reset'
            color={hexToRGBA('#F52424', 1)}
            tonalidad='20'
            borde='5px'
            margenExterno='5px 5px 5px 5px'
            margenInterno={datos.esComputadora ? '10px' : '15px 2px 15px 2px'}
            fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
            width={datos.esComputadora ? '100%' : '100%'}
            estaProcesando={estaProcesando}
            onClick={limpiarFormulario}
          />
        </div>
      </form>
    </>
  )
}
function hexToRGBA (hex, opacidad) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}
export default CrearPersona
