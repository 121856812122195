import React, { useState, useEffect, useRef } from 'react'
import * as estilos from './ModalPedidosCSS'
import Boton from '../../../../Elementos/Boton'
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer
} from '@react-pdf/renderer'

import { 
  hexToRGBA,
  formatearFecha2,
  capitalizeWords,
  formatearFecha3,
  formatearMonedaSoles,
  eliminarPedido
 } from './../../../../ApiSanMiguel/Api'

function ModalPedidos (datos) {
  //const [verPdf2, setVerPdf] = useState(datos.verpdf)
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rangoFechas, setrangoFechas] = useState(datos.rangoFechas)
  const [asistenciaDetalleData, setAsistenciaDetData] = useState(datos.asistenciaDetalleData)

  useEffect(
    ()=>{
      console.log(asistenciaDetalleData)
    }, []
  )

  return (
    <>
      {console.log(datos.esComputadora)}
      <div
        id='baseModal'
        style={estilos.baseModal(datos.esComputadora)}
      >
        {datos.mostrarModal && (
          <div id='contenedorModal' style={estilos.contenedorModal(datos.esComputadora)}>
            
            <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}>
              <Boton
                moderno='X'
                tipo='submit'
                color={hexToRGBA('#E12121', 1)}
                tonalidad='20'
                borde='5px 10px 5px'
                margenExterno={datos.esComputadora ? '0vw' : '0vw'}
                margenInterno={
                  datos.esComputadora ? '0.6vw 0.2vw' : '1vw 2vw 1vw 2vw'
                }
                fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
                width={datos.esComputadora ? '5vh' : '7vw'}
                height={datos.esComputadora ? '5vh' : '7vw'}
                estaProcesando={estaProcesando}
                onClick={datos.cerrarPdfModalClick}
                labelStyle={{
                  marginTop: datos.esComputadora ? '' : '30%',
                  fontSize: datos.esComputadora ? '2vh' : '3vw'
                }}
              />
            </div>
            <div id="contenidoModal" style={estilos.contenidoModal(datos.esComputadora)}>
                <div style={{ 
                    gridArea: datos.esComputadora ? '2/2/3/10':'2/2/3/10',
                    //fontSize: datos.esComputadora ? "1vw":"2.5vw"
                    }}>
                    ¿Seguro que deseas eliminar el pedido?
                </div>
                
                <div style={{ 
                    gridArea: datos.esComputadora ? '3/2/4/10':'3/2/4/10', 
                    fontWeight: "bolder",
                    //fontSize: datos.esComputadora ? "1vw":"2.5vw"
                    }}>
                    Nro. {String(datos.codigoPedidoEliminar).padStart(8, '0')}
                </div>
                <div style={{ gridArea: datos.esComputadora ? ' 10/2/11/5':' 10/2/11/5',}}>
                    <Boton
                        moderno='Confirmar'
                        tipo='submit'
                        color={hexToRGBA('#2D8B0B', 1)}
                        tonalidad='20'
                        borde='5px'
                        margenExterno={datos.esComputadora ? '0.1vw':'0.5vw'}
                        margenInterno={datos.esComputadora ? '0.4vw':'1vw'}
                        estaProcesando={estaProcesando}
                        onClick={async(event)=>{
                            event.preventDefault();
                            setestaProcesando(true);
                            const respuesta = await eliminarPedido(datos.codigoPedidoEliminar)
                            if (respuesta){
                                console.log(respuesta)
                            }
                            datos.cerrarPdfModalClick();
                            setestaProcesando(false);
                        }}
                        fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                        width= {datos.esComputadora ? "100%":"100%"}
                        height="4vh"
                    />
                </div>
                <div style={{  gridArea: datos.esComputadora ? ' 10 / 7/ 11 / 10':' 10 / 7/ 11 / 10',marginBottom: "1vh"}}>
                    <Boton
                        moderno='Cancelar'
                        tipo='submit'
                        color={hexToRGBA('#E12121', 1)}
                        tonalidad='20'
                        borde='5px'
                        margenExterno={datos.esComputadora ? '0.1vw':'0.5vw'}
                        margenInterno={datos.esComputadora ? '0.4vw':'1vw'}                                
                        estaProcesando={estaProcesando}
                        fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                        width= {datos.esComputadora ? "100%":"100%"}
                        height="4vh"
                        onClick={(event)=>{
                            event.preventDefault();
                            datos.cerrarPdfModalClick();
                        }}
                    />
                </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ModalPedidos
