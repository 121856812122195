import {
    hexToRGBA
} from './../../../../ApiSanMiguel/Api'

import {StyleSheet } from '@react-pdf/renderer';

export const baseModal=(esComputadora)=>({
    position: "absolute",
    left: "0",
    top: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: hexToRGBA('#C1C1C1', 0.5)
})
export const botonCerrarModalStyle=(esComputadora)=>({
    display: "flex",
    gridArea: esComputadora ? ' 1 / 18 / 2 / 21':' 1 / 18 / 2 / 21',
    justifySelf: "right",
    alignItems: "center",
})

const widthModal=(esComputadora)=>{
    return esComputadora ? "25":"70"
}
const heightModal=(esComputadora)=>{
    return esComputadora ? "15":"15"
}
export const contenedorModal=(esComputadora)=>({
    borderRadius: "10px 10px 0px 0px",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridTemplateRows: "repeat(2,1fr)",
    position: "absolute",
    left: ((100-widthModal(esComputadora))/2)+"vw",
    top: ((100-heightModal(esComputadora))/2)+"vh",
    width: widthModal(esComputadora)+"vw",
    backgroundColor: hexToRGBA("#ffffff",1),
    zIndex: "41", 
    boxSizing: "border-box"
  })
export const contenidoModal=(esComputadora)=>({
    gridArea : "1/1/21/21",
    backgroundColor: "red",
    position: "absolute",
    top: "5vh",
    width: widthModal(esComputadora)+"vw",
    height: heightModal(esComputadora)+"vh",
    backgroundColor: hexToRGBA("#ffffff",1),
    zIndex: "42", 
    borderRadius: "0px 0px 10px 10px",
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    gridTemplateRows: "repeat(10,1fr)",
  })


