import {
    hexToRGBA
} from './../../../../ApiSanMiguel/Api'

import {StyleSheet } from '@react-pdf/renderer';


export const contenedorAsistenciasCSS= (esComputadora,visualizoDetalle)=>({
    gridArea: visualizoDetalle ? "4/1/21/21":"1/1/21/21",
    backgroundColor: "white",
    display: 'grid',
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateColumns: 'repeat(1, 1fr)',
})


export const contenidoAsistenciasGlobalCSS= (esComputadora)=>({
    gridArea: "1/1/21/21",
    backgroundColor: "white",
    display: 'grid',
    gridTemplateRows: 'repeat(10, 1fr)',
    gridTemplateColumns: 'repeat(10, 1fr)',
})

export const contenidoCSS= (esComputadora)=>({
    gridArea: "1/1/11/11",
    backgroundColor: "white",
    display: 'grid',
    gridTemplateRows: 'repeat(10, 1fr)',
    gridTemplateColumns: 'repeat(10, 1fr)',
    overflow: "auto",
    width: "100%",
    height: "100%",

})

export const registroAsistenciaTituloCSS= (esComputadora)=>({
    backgroundColor : "#AD6726",
    display: 'grid',
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateColumns: 'repeat(3, 1fr)',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ccc',
    color: "white",
    fontWeight: "bolder",
    padding: "10px",
    borderRadius: "15px 15px 0px 0px",
    fontSize: esComputadora ? "1vw": "2.5vw",
    textAlign: 'Center',
})

export const registroAsistenciaCSS= (esComputadora)=>({
    //backgroundColor : "green",
    overflow: "auto",
    marginTop: "5px",
    display: 'grid',
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateColumns: 'repeat(3, 1fr)',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ccc',
    color: "black",
    fontWeight: "bolder",
    padding: "10px",
    borderRadius: "10px ",
    fontSize: esComputadora ? "1vw": "2.5vw",
    textAlign: 'Center',
    cursor: "pointer"
})

export const contenidoRegistroAsistenciaCSS=(esComputadora)=>({
    gridArea: "1/1/10/11"
})

export const registroAsistenciaDetalleTituloCSS= (esComputadora)=>({
    backgroundColor : "#AD6726",
    display: 'grid',
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateColumns: 'repeat(9, 1fr)',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ccc',
    color: "white",
    fontWeight: "bolder",
    padding: "10px",
    borderRadius: "15px 15px 0px 0px",
    fontSize: esComputadora ? "1vw": "2.5vw",
    textAlign: 'Center',
    overflow: "auto",
    width: esComputadora ? "": "150vw",
    boxSizing:"border-box",
})

export const registroAsistenciaDetalleCSS= (esComputadora)=>({
    //backgroundColor : "green",
    overflow: "auto",
    width: esComputadora ? "": "150vw",
    marginTop: "5px",
    display: 'grid',
    gridTemplateRows: 'repeat(1, 1fr) 40px',
    gridTemplateColumns: 'repeat(9, 1fr)',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ccc',
    color: "black",
    fontWeight: "bolder",
    padding: "10px",
    borderRadius: "10px ",
    fontSize: esComputadora ? "1vw": "2.2vw",
    textAlign: 'Center',
    cursor: "pointer",
    boxSizing:"border-box",
})

export const datosUsuarioCSS = (esComputadora) => ({
   // gridArea:"1/1/2/3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing:"border-box",
    backgroundColor: "#ccc",
    border: '1px solid #ccc',
    borderRadius: "10px 0px 0px 10px ",
    padding: "0px",
    fontSize: esComputadora ? "1vw": "2.5vw",
})

export const footerOpcionesCSS=(esComputadora)=>({
    gridArea: "1/1/3/21",
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateRows: "repeat(1, 1fr)",
    gridTemplateColumns: "repeat(20, 1fr)",
    borderBottom: "2px solid #ccc"
})

const coloresDet={"ingreso":"#fff6de","salida":"#fff6de","pago":"#eee","pagoTotal":"#f1c232"}
const coloresDesc={"ingreso":"#eee","salida":"#eee","pago":"#eee","pagoTotal":"#f1c232"}

export const registroDescCSS = (esComputadora,tipo)=>({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"5px 0px 5px 0px",
    backgroundColor:  coloresDesc[tipo], 
    borderRight: "1px solid #aaa",
    borderLeft: "1px solid #aaa",
    marginLeft: "1px",
    borderRadius: "5px"
})

export const registroDetCSS = (esComputadora,tipo)=>({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:"5px 0px 5px 0px",
    backgroundColor:  coloresDet[tipo],
    borderRadius: "5px",
    borderRight: "1px solid #aaa",
    borderLeft: "1px solid #aaa",
    fontSize: tipo === "pagoTotal" ? esComputadora ? "1.5vw":"3vw":""
})

export const asistenciPDfCSS = (esComputadora)=>({
    gridArea: "1/18/2/21",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
})
export const RegresarCSS = (esComputadora)=>({
    gridArea: "1/1/2/4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
    
})
export const MontoTotalDetalle = (esComputadora)=>({
  gridArea: "1/7/2/14",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bolder",
  fontSize: esComputadora ? "1vw": "2.5vw",
})
//PDF
export const contenedorPdf=(esComputadora)=>({
    position: "absolute",
    left: "0",
    top: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: hexToRGBA('#C1C1C1', 0.5)
})
export const botonCerrarModalStyle=(esComputadora)=>({
    display: "flex",
    gridArea: esComputadora ? ' 1 / 18 / 2 / 21':' 1 / 18 / 2 / 21',
    justifySelf: "right",
    alignItems: "center",
})
export const pdfContenidoStyle=(esComputadora)=>({
    gridArea : "1/1/21/21",
    backgroundColor: "red",
    position: "absolute",
    left: "0",
    top: "5vh",
    width: "90vw",
    height: "85vh",
    backgroundColor: hexToRGBA("#323639",0.8),
    zIndex: "42", 
  })

export const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop:"10px"
  },
  tableRow: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexDirection: "column"
  },
  tableCol: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexDirection: "row"
  },
  tableCell: {
    fontSize: "10px",
    width: "12.5%",
    height: "13px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  tableCellTitulo: {
    fontSize: "10px",
    width: "14.5%",
    height: "25px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  rowspanCell: {
    width: "25%",
    fontSize: "12px",
    height: "52px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    
  },
  })

export const pdfStyleModal=(esComputadora)=>({
    borderRadius: "5px",
    display: "grid",
    gridTemplateColumns: "repeat(20,1fr)",
    gridTemplateRows: "repeat(20,1fr)",
    position: "absolute",
    left: "5vw",
    top: "5vh",
    width: "90vw",
    height: "90vh",
    backgroundColor: hexToRGBA("#323639",1),
    zIndex: "41", 
    boxSizing: "border-box"
  })
//FIN PDF