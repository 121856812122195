import React, { useState, useEffect } from 'react'
import {
  listaDistribuidores,
  listarAyudantesDistribuidor,
  listarPedidos,
  obtenerCookie
} from './../../../../ApiSanMiguel/Api'
import Opciones from './../../../../Elementos/Opciones'
import DetallePedido from './DetallePedido'
import Boton from './../../../../Elementos/Boton'
import borrar from './../../../../Imagenes/Iconos/borrar.png'
import ModalPedidos from './ModalPedidos'
import ModalVerPagoPedido from './ModalVerPagoPedido'
import ModalGenerarPagoPedido from './ModalGenerarPagoPedido'

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

function ListarPedidos (datos) {
  const [isFocused, setIsFocused] = useState(false)
  const [distribuidores, setDistribuidores] = useState(null)
  const [distribuidorSeleccionado, setDistribuidorSeleccionado] = useState('')
  const [ayudantes, setAyudantes] = useState(null)
  const [ayuDistSelec, setayuDistSelec] = useState('')
  const [pedidos, setPedidos] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [idHovered, setIdHovered] = useState('')
  const [codPedidos, setCodPedidos] = useState({})
  const [idSeleccionado, setIdSeleccionado] = useState(null)
  const [distSeleccionadoModal, setdistSeleccionadoModal] = useState(null)
  const [coddistSeleccionadoModal, setcoddistSeleccionadoModal] = useState(null)
  const [fecSolSeleccionadoModal, setfecSolSeleccionadoModal] = useState(null)
  const [modalDetalle, setModalDetalle] = useState(false)
  const [rol, setRol] = useState(
    obtenerCookie('descRol') !== null
      ? obtenerCookie('descRol').toUpperCase().trim()
      : ''
  )
  const [codUsuarioSesion, setcodUsuarioSesion] = useState(
    obtenerCookie('codUsuario') !== null
      ? obtenerCookie('codUsuario').toUpperCase().trim()
      : ''
  )
  const [eliminar, setEliminar] = useState(false)
  const [codigoPedidoEliminar, setCodigoPedidoEliminar] = useState(0)
  const [nombreUsuarioPedido, setNombreUsuarioPedido] = useState('')
  const [deudaTotal, setDeudaTotal] = useState(0)
  const [modalPago, setModalPago] = useState(false)
  const [modalGenerarPago, setModalGenerarPago] = useState(false)
  useEffect(() => {
    const listaDistribuidoresData = async () => {
      if (rol === 'DISTRIBUIDOR DE PRODUCTOS') {
        const respuesta = await listarAyudantesDistribuidor(codUsuarioSesion)
        if (respuesta.SMListaErrores.length === 0) {
          setAyudantes(respuesta.ayudanteDistribuidores)
        } else {
          setAyudantes(null)
        }
      } else {
        const respuesta = await listaDistribuidores() // Esta función debe retornar el resultado que necesitas para la validación
        const respuesta2 = await listarPedidos(ayuDistSelec ==="" ? distribuidorSeleccionado===""?'':distribuidorSeleccionado:ayuDistSelec, 3, 1)
        console.log(respuesta2)
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setDistribuidores(respuesta.Distribuidores)
          }
        }
        if (respuesta2) {
          if (respuesta2.SMListaErrores.length === 0) {
            setPedidos(respuesta2.Pedidos)
            console.log(respuesta2.Deuda)
            setDeudaTotal(respuesta2.Deuda)
          }
        }
      }
    }
    listaDistribuidoresData()
  }, [eliminar])

  useEffect(() => {
    const listaDistribuidoresData = async () => {
      const respuesta = await listaDistribuidores() // Esta función debe retornar el resultado que necesitas para la validación
      const respuesta2 = await listarPedidos(ayuDistSelec ==="" ? distribuidorSeleccionado===""?'':distribuidorSeleccionado:ayuDistSelec, 3, 1)
      if (respuesta.SMListaErrores.length === 0) {
        setDistribuidores(respuesta.Distribuidores)
      }
      if (respuesta2.SMListaErrores.length === 0) {
        setPedidos(respuesta2.Pedidos)
      }
    }
    listaDistribuidoresData()
  }, [modalDetalle])

  useEffect(() => {
    if (pedidos !== null) {
      pedidos.forEach(pedido => {
        setCodPedidos(prevState => ({
          ...prevState,
          [pedido.codPedido]: false
        }))
      })
    }
  }, [pedidos])

  const distribuidorSeleccionar = async valor => {
    console.log(valor)
    setDistribuidorSeleccionado(valor)
  }

  useEffect(() => {
    if (distribuidorSeleccionado !== null) {
      async function fetchData () {
        const respuesta = await listarAyudantesDistribuidor(
          distribuidorSeleccionado
        )
        const respuesta2 = await listarPedidos(distribuidorSeleccionado, 3, 1)
        if (respuesta.SMListaErrores.length === 0) {
          setAyudantes(respuesta.ayudanteDistribuidores)
        } else {
          setAyudantes(null)
        }
        if (respuesta2.SMListaErrores.length === 0) {
          setPedidos(respuesta2.Pedidos)
          setDeudaTotal(respuesta2.Deuda)
          //console.log(respuesta2)
        }
      }
      fetchData()
    }
  }, [distribuidorSeleccionado])

  const ayudanteDistribuidor = valor => {
    console.log(valor)
    console.log(distribuidorSeleccionado)
    setayuDistSelec(valor)
  }

  useEffect(() => {
    if (ayuDistSelec !== null) {
      async function fetchData () {
        const respuesta = await listarPedidos(ayuDistSelec === '' ? distribuidorSeleccionado:ayuDistSelec, 3, 1)
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setPedidos(respuesta.Pedidos)
            setDeudaTotal(respuesta.Deuda)
            //setAyudantes(respuesta.ayudanteDistribuidores);
          } else {
            //setAyudantes(null)
          }
        }
      }
      fetchData()
    }
  }, [ayuDistSelec])

  const listaDistStyle = {
    display:
      rol !== 'DISTRIBUIDOR DE PRODUCTOS'
        ? rol === 'AYUDANTE DE DISTRIBUIDOR'
          ? 'none'
          : ''
        : 'none',
    padding: '0',
    margin: '0',
    //backgroundColor: "green",
    boxSizing: 'border-box',
    height: '40px',
    width: '100%',
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 10' : '1 / 1 / 2 / 11'
  }
  const listaAyuDistStyle = {
    display: rol === 'AYUDANTE DE DISTRIBUIDOR' ? 'none' : '',
    padding: '0',
    margin: '0',
    //backgroundColor: "green",
    boxSizing: 'border-box',
    height: '40px',
    width: '100%',
    gridArea: datos.esComputadora ? '1 / 11 / 2 / 21' : '1 / 12 / 2 / 21'
  }
  const contenedorListaPedidosstyle = {
    overflow: 'auto',
    gridArea: datos.esComputadora ? '3 / 1 / 21 / 21' : '3 / 1 / 21 / 21'
  }
  const contenedorNombreYPedido = {
    display: 'flex'
  }
  const nomDistStyle = {
    //backgroundColor: "red",
    fontSize: '1em',
    color: '#363636',
    fontWeight: 'bolder',
    marginBottom: '5px',
    alignItems: 'flex-start'
  }
  const nroPedidoStyle = {
    marginLeft: 'auto',
    fontWeight: 'bolder',
    color: '#363636'
  }

  const contUsrRecStyle = {
    display: 'block',
    //backgroundColor: "yellow",
    fontSize: '0.8em',
    color: '#868686'
  }
  const usrRecStyle = {
    display: 'inline-block',
    //backgroundColor: "orange",
    fontSize: '0.9em !important',
    color: '#737373',
    fontWeight: '700',
    marginRight: '5px'
  }
  const contFecSolStyle = {
    display: 'inline-block',
    //backgroundColor: "purple",
    fontSize: '0.6em ',
    width: 'calc(100%/3)',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    color: '#737373'
  }
  const labFecSolStyle = {
    display: 'flex',
    //backgroundColor: "#17BDBB",
    height: '6vh',
    justifyContent: 'center' /* Centra horizontalmente */,
    alignItems: 'center',
    textAlign: 'center',
    color: '#737373',
    fontWeight: '700',
    marginTop: '5px',
    marginBottom: datos.esComputadora ? '3px' : '-15px'
  }
  const contenedorTotales = {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '33% 33% 33%',
    marginTop: '10px',
    marginBottom: '-3px'
  }
  const contTotPeStyle = {
    //backgroundColor: "Pink",
    fontSize: '0.8em ',
    gridArea: '1/1/2/2',
    textAlign: 'center',
    color: '#737373'
  }
  const contTotEnStyle = {
    //backgroundColor: "Brown",
    fontSize: '0.8em ',
    gridArea: '1/3/2/4',
    textAlign: 'center',
    //marginRight: "10%",
    color: '#737373'
  }
  const contMediumStyle = {
    //backgroundColor: "Brown",
    fontSize: '0.8em ',
    gridArea: '1/2/2/3',
    textAlign: 'center',
    //marginRight: "10%",
    color: '#737373'
  }
  const labelTotPeStyle = {
    display: 'block',
    color: '#737373',
    fontWeight: '700'
  }
  const labelMediumStyle = {
    display: 'block',
    color: '#737373',
    fontWeight: '700'
  }
  const labelTotEnStyle = {
    display: 'block',
    color: '#737373',
    fontWeight: '700'
  }

  const estiloHover = {
    ...(isHovered && {
      cursor: 'pointer'
      //backgroundColor: hexToRGBA('#DCAD2F', 1.0),
      //boxShadow: 'inset 0 0 22px #FFFFFF',
      //...(!datos.esComputadora && { borderBottom: '5px solid #603711' }),
      //...(datos.esComputadora && { borderLeft: '5px solid #603711' })
    })
  }
  const cardPedidostyle = {
    //backgroundColor: "#FFF7A9",//"#FCFCFC",
    display: datos.esComputadora ? 'inline-block' : '',
    borderRadius: '20px',
    margin: '20px 10px 0px 0px',
    padding: '10px',
    boxSizing: 'border-box',
    width: datos.esComputadora ? 'calc(100%/3.15)' : '100%',
    border: '1px solid #ccc',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
    ...(isHovered ? estiloHover : '')
  }
  const manejadorOpcionClick = async event => {
    setIdSeleccionado(event.currentTarget.id)
    setdistSeleccionadoModal(
      capitalizeWords(event.currentTarget.getAttribute('nombre'))
    )
    setcoddistSeleccionadoModal(
      event.currentTarget.getAttribute('codigoDistribuidor')
    )
    setfecSolSeleccionadoModal(
      event.currentTarget.getAttribute('fechasolicitada')
    )
    setModalDetalle(true)
  }
  const cerrarModalClick = () => {
    setModalDetalle(false)
  }
  const modalStyle = {
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100vw',
    height: '100vh',
    backgroundColor: hexToRGBA('#C1C1C1', 0.8),
    zIndex: '20',
    boxSizing: 'border-box'
  }
  const contContenidoModalStyle = {
    position: 'relative',

    width: '90vw',
    height: '90vh',
    left: '5vw',
    boxSizing: 'border-box',
    top: '5vh'
  }
  const contenidoModalStyle = {
    display: 'grid',
    gridTemplateRows: 'repeat(20, 1fr)',
    gridTemplateColumns: 'repeat(20, 1fr)',
    width: '90vw',
    height: '90vh',
    overflow: 'auto',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '10px'
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  })

  const cajaTotalStyle = {
    padding: '0',
    marginTop: '5px',
    //backgroundColor: "green",
    boxSizing: 'border-box',
    height: '40px',
    width: '100%',
    gridArea: datos.esComputadora ? '2 / 1 / 3 / 21' : '2 / 1 / 3 / 21',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
  const cantTotalStyle = {
    display: 'inline-block',
    fontWeight: '500',
    fontSize: datos.esComputadora ? '2vw' : '2vh',
    textAlign: 'left'
  }
  const botonEliminarStyle = esComputadora => ({
    gridArea: '1/3/2/4',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer'
    //filter: "drop-shadow(0.5px 0.5px 0.5px rgba(1, 1, 1, 0.9))",
  })

  const imagenBotonEliminarStyle = esComputadora => ({
    width: esComputadora ? '1.5vw' : '2.6vh',
    height: 'auto'
  })
  return (
    <>
      {eliminar && (
        <ModalPedidos
          esComputadora={datos.esComputadora}
          cerrarPdfModalClick={() => {
            setEliminar(false)
          }}
          mostrarModal={true}
          codigoPedidoEliminar={codigoPedidoEliminar}
        />
      )}
      {modalPago && (
        <ModalVerPagoPedido
          esComputadora={datos.esComputadora}
          cerrarPdfModalClick={() => {
            setModalPago(prevState => !prevState)
          }}
          mostrarModal={true}
          codigoPedidoEliminar={codigoPedidoEliminar}
          nombreUsuarioPedido={nombreUsuarioPedido}
        />
      )}
      {modalGenerarPago && (
        <ModalGenerarPagoPedido
          esComputadora={datos.esComputadora}
          cerrarPdfModalClick={() => {
            setModalGenerarPago(prevState => !prevState)
          }}
          mostrarModal={true}
          codigoPedidoEliminar={codigoPedidoEliminar}
          nombreUsuarioPedido={nombreUsuarioPedido}
        />
      )}
      {modalDetalle ? (
        <div id='modalStyle' style={modalStyle}>
          <div id='contContenidoModalStyle' style={contContenidoModalStyle}>
            <div id='contenidoModalStyle' style={contenidoModalStyle}>
              <DetallePedido
                esComputadora={datos.esComputadora}
                idPedido={idSeleccionado}
                nombreDistribuidor={distSeleccionadoModal}
                fecSol={fecSolSeleccionadoModal}
                codigoDistribuidor={coddistSeleccionadoModal}
                cerrarModalClick={cerrarModalClick}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <div id='contenedorListaDist' style={listaDistStyle}>
        <Opciones
          moderno='Distribuidor'
          opciones={distribuidores !== null ? distribuidores : []}
          clave='codUsuario'
          valor={['Nombre', 'ApellidoPaterno']}
          esComputadora={datos.esComputadora}
          onClick={distribuidorSeleccionar}
        />
      </div>
      <div id='contenedorListaAyuDist' style={listaAyuDistStyle}>
        <Opciones
          moderno='Ayudante Distribuidor'
          opciones={ayudantes !== null ? ayudantes : []}
          clave='codUsuario'
          valor={['Nombre', 'ApellidoPaterno']}
          esComputadora={datos.esComputadora}
          onClick={ayudanteDistribuidor}
        />
      </div>
      { (rol === "SUPER ADMINISTRADOR"||rol === "DISTRIBUIDOR DE PRODUCTOS")  &&
      <div style={cajaTotalStyle}>
        <span
          style={{
            ...labelTotEnStyle,
            fontSize: datos.esComputadora ? '2vw' : '5vw'
          }}
        >
          Deuda Total : {formatearMonedaSoles(deudaTotal ? deudaTotal : 0)}
        </span>
      </div>
      }
      <div id='contenedorListaPedidos' style={contenedorListaPedidosstyle}>
        {pedidos !== null &&
          pedidos.map(i => {
            return (
              <div
                key={i.codPedido}
                id={i.codPedido}
                nombre={i.nombreUsuarioSolicitante}
                fechasolicitada={i.fechaPedido}
                codigodistribuidor={i.usuarioSolicitante}
                style={{
                  backgroundColor: i.entregado === 'N' ? '#FFF7A9' : '#FCFCFC',
                  ...cardPedidostyle
                }}
                onMouseEnter={event => {
                  setIsHovered(true)
                  setIdHovered(event.target.id)
                }}
                onMouseLeave={() => {
                  setIsHovered(false)
                  setIdHovered('')
                }}
                onClick={manejadorOpcionClick}
              >
                <div style={contenedorNombreYPedido}>
                  <span style={nomDistStyle}>
                    {capitalizeWords(
                      i.nombreUsuarioSolicitante !== null
                        ? i.nombreUsuarioSolicitante
                        : ''
                    )}
                  </span>
                  <span style={nroPedidoStyle}>
                    Nro. {String(i.codPedido).padStart(8, '0')}
                  </span>
                </div>
                <span style={contUsrRecStyle}>
                  <label style={usrRecStyle}>Recepcionante:</label>
                  {capitalizeWords(
                    i.nombreUsuarioRecepcionante !== null
                      ? i.nombreUsuarioRecepcionante
                      : ''
                  )}
                </span>
                <span style={contUsrRecStyle}>
                  <label style={usrRecStyle}>Despachador:</label>
                  {capitalizeWords(
                    i.nombreUsuarioEntrega !== null
                      ? i.nombreUsuarioEntrega
                      : '-------'
                  )}
                </span>
                <span style={contFecSolStyle}>
                  <label style={labFecSolStyle}>F. Solicitud</label>
                  {formatDateTime(i.fechaPedido)}
                </span>
                <span style={contFecSolStyle}>
                  <label style={labFecSolStyle}>F. Entrega Programada</label>
                  {formatDateTime(i.fechaEntregada)}
                </span>
                <span style={contFecSolStyle}>
                  <label style={labFecSolStyle}>F. Entrega</label>
                  {i.fechaEntregadaReal === null
                    ? '-------'
                    : formatDateTime(i.fechaEntregadaReal)}
                </span>
                <div style={contenedorTotales}>
                  <span style={contTotPeStyle}>
                    <label style={labelTotPeStyle}>Total Pedido</label>
                    {formatearMonedaSoles(i.totalPedido)}
                  </span>
                  <span
                    style={
                      i.entregado === 'S' ? contMediumStyle : contTotEnStyle
                    }
                  >
                    <label style={labelTotEnStyle}>Total Entregado</label>
                    {formatearMonedaSoles(i.totalEntrega)}
                  </span>
                  {i.entregado === 'S' && (
                    <span style={contTotEnStyle}>
                      <label style={labelTotEnStyle}>Total Pagado</label>
                      {formatearMonedaSoles(i.montoPagado)}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    ...contenedorTotales,
                    gridTemplateColumns: '30% 60% 10%'
                  }}
                >
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    
                    <Boton
                      moderno={i.deuda !== 0
                        ? 'Saldo: ' + formatearMonedaSoles(i.deuda)
                        : ''}
                      labelStyle={{color: "white"}}
                      tipo='submit'
                      color={i.deuda !== 0 ? hexToRGBA('#F52424', 1) : 'transparent'}
                      tonalidad='0'
                      borde='5px'
                      margenExterno='5px 5px 5px 5px'
                      margenInterno={datos.esComputadora ? '4px' : '15px 2px 15px 2px'}
                      fontSize={datos.esComputadora ? '1vw' : '2.5vw'}
                      width={datos.esComputadora ? '100%' : '100%'}
                      estaProcesando={false}
                      onClick={(e)=>{
                        e.stopPropagation()
                        setModalGenerarPago(prevState => !prevState)
                        setCodigoPedidoEliminar(
                          e.currentTarget.parentNode.parentNode.parentNode.id
                        )
                        setNombreUsuarioPedido(
                          e.currentTarget.parentNode.parentNode.parentNode.getAttribute(
                            'nombre'
                          )
                        )
                      }

                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {rol === 'SUPER ADMINISTRADOR' &&
                      i.entregado === 'S' &&
                      i.deuda !== 0 && (
                        <>
                          <Boton
                            moderno='Generar Pago'
                            labelStyle={{color: "black"}}
                            tipo='submit'
                            color={hexToRGBA('#DCAD2F', 1)}
                            tonalidad='20'
                            borde='5px'
                            margenExterno='5px 5px 5px 5px'
                            margenInterno={datos.esComputadora ? '4px' : '15px 2px 15px 2px'}
                            fontSize={datos.esComputadora ? '1vw' : '2.5vw'}
                            width={datos.esComputadora ? '100%' : '100%'}
                            estaProcesando={false}
                            onClick={(e)=>{
                              e.stopPropagation()
                              setModalGenerarPago(prevState => !prevState)
                              setCodigoPedidoEliminar(
                                e.currentTarget.parentNode.parentNode.parentNode.id
                              )
                              setNombreUsuarioPedido(
                                e.currentTarget.parentNode.parentNode.parentNode.getAttribute(
                                  'nombre'
                                )
                              )
                            }

                            }
                          />
                        </>
                      )}
                    {i.entregado === 'S' && (
                      <Boton
                            moderno='Ver Pagos'
                            labelStyle={{color: "black"}}
                            tipo='submit'
                            color={hexToRGBA('#DCAD2F', 1)}
                            tonalidad='20'
                            borde='5px'
                            margenExterno='5px 5px 5px 5px'
                            margenInterno={datos.esComputadora ? '10px' : '15px 2px 15px 2px'}
                            fontSize={datos.esComputadora ? '1vw' : '2.5vw'}
                            width={datos.esComputadora ? '100%' : '100%'}
                            estaProcesando={false}
                            onClick={e => {
                              e.stopPropagation()
                              setModalPago(prevState => !prevState)
                              setCodigoPedidoEliminar(
                                e.currentTarget.parentNode.parentNode.parentNode.id
                              )
                              setNombreUsuarioPedido(
                                e.currentTarget.parentNode.parentNode.parentNode.getAttribute(
                                  'nombre'
                                )
                              )
                            }
                            }
                          />
                    )}
                  </div>

                  {(i.totalEntrega === 0 || rol === 'SUPER ADMINISTRADOR') && (
                    <button
                      style={botonEliminarStyle(datos.esComputadora)}
                      onClick={e => {
                        e.stopPropagation()
                        setEliminar(true)
                        setCodigoPedidoEliminar(
                          e.currentTarget.parentNode.parentNode.id
                        )

                        //setCodigoPedidoEliminar();
                      }}
                    >
                      <img
                        src={borrar}
                        style={imagenBotonEliminarStyle(datos.esComputadora)}
                      />
                    </button>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

function capitalizeWords (str) {
  let words = str.toLowerCase().split(' ')
  let capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })
  return capitalizedWords.join(' ')
}

function formatDateTime (dateTimeStr) {
  // Expresión regular para extraer partes de la fecha y hora UTC
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.\d{3})?Z$/

  // Intentar hacer coincidir la cadena de fecha y hora con la expresión regular
  const match = dateTimeStr.match(regex)

  if (!match) {
    // Manejo de error si el formato de entrada no es válido
    console.error('Formato de fecha y hora no válido:', dateTimeStr)
    return null
  }

  // Extraer partes de la fecha y hora UTC
  const [, year, month, day, hour, minute] = match

  // Convertir la hora de formato de 24 horas a formato de 12 horas con AM/PM
  let formattedHour = parseInt(hour, 10)
  const ampm = formattedHour >= 12 ? 'pm' : 'am'
  formattedHour = formattedHour % 12
  formattedHour = formattedHour ? formattedHour : 12 // Hora '0' debería ser '12'

  // Formatear la hora en formato de 12 horas con AM/PM
  const formattedTime = `${formattedHour}:${minute} ${ampm}`

  // Formatear la fecha en dd/mm/yyyy
  const formattedDate = `${day}/${month}/${year}`

  // Salida final en el formato deseado
  const formattedDateTime = `${formattedDate} ${formattedTime}`

  return formattedDateTime
}

function hexToRGBA (hex, opacidad) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}
function formatearMonedaSoles (valor) {
  return valor.toLocaleString('es-PE', {
    style: 'currency',
    currency: 'PEN'
  })
}
export default ListarPedidos
