import React, { useState,useEffect, Fragment } from 'react'
import { 
  listarAyudantesDistribuidor,
  detallePedido,
  actualizarEntrega,
  actualizarPedido,
  entregar,
  detallePedidoGenerico,
  obtenerCookie, 
  PDFDownloadLink } from './../../../../ApiSanMiguel/Api'
import Opciones from './../../../../Elementos/Opciones'
import Input from './../../../../Elementos/Input'
import Boton from './../../../../Elementos/Boton'

import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';


function DetallePedido (datos) {
  const [isFocused, setIsFocused] = useState(false)
  const [distribuidores, setDistribuidores] = useState(null)
  const [distribuidorSeleccionado, setDistribuidorSeleccionado] = useState(null)
  const [ayudantes, setAyudantes] = useState(null)
  const [ayuDistSelec, setayuDistSelec] = useState(null)
  const [fecRecojo, setfecRecojo] = useState(null)
  const [ultimosPedidos, setUltimosPedidos] = useState(null)
  const [codPedido,setCodPedido] = useState(null)
  const [subTotales, setSubTotales] = useState([])
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rol, setRol] = useState(obtenerCookie("descRol")!==null ? obtenerCookie("descRol").toUpperCase().trim():"")
  const [verPdf, setVerPdf] = useState(false)
  const [codUsuario, setCodUsuario] = useState(obtenerCookie("codUsuario")!==null ? obtenerCookie("codUsuario").toUpperCase().trim():"")
  const [editarPedido, seteditarPedido] = useState(false)
  const [editarEntrega, setEditarEntrega] = useState(false)

  useEffect(() => {
    const listaDistribuidoresData = async () => {
      if(rol==='DESPACHADOR'){
        const respuesta = await detallePedido(datos.idPedido,codUsuario); // Esta función debe retornar el resultado que necesitas para la validación
        if(respuesta){
          console.log(respuesta)
          if(respuesta.SMListaErrores.length ===0){
              setUltimosPedidos(respuesta)
          }
        }
      }
      else{
        const respuesta = await detallePedido(datos.idPedido);//detallePedidoGenerico(datos.idPedido); // Esta función debe retornar el resultado que necesitas para la validación
        //const respuesta2 = await listarPedidos("",1,1);
        if(respuesta){
          console.log(respuesta)
          if(respuesta.SMListaErrores.length ===0){
              setUltimosPedidos(respuesta)
          }
        }
      }
    }
    listaDistribuidoresData();
  }, [editarPedido,editarEntrega]);

  const distribuidorSeleccionar = async (valor) => {
    setDistribuidorSeleccionado(valor);
  };

  useEffect(() => {
    if (distribuidorSeleccionado !== null) {
      async function fetchData() {
        const respuesta = await listarAyudantesDistribuidor(distribuidorSeleccionado);
        const respuesta2 = await detallePedido(distribuidorSeleccionado);
        if (respuesta){
          if (respuesta.SMListaErrores.length === 0) {
            setAyudantes(respuesta.ayudanteDistribuidores);
            
          }else{
              setAyudantes(null)
          }
        }
        if( respuesta2){
          if(respuesta2.SMListaErrores.length ===0){
            setUltimosPedidos(respuesta2)
            setCodPedido(respuesta2.codPedido)
          }
          else{
            setUltimosPedidos(null)
            setayuDistSelec(null)
            
          }
        }
        
      }
      fetchData();
    }
    else{
      setayuDistSelec(null)
    }
  }, [distribuidorSeleccionado]);
  
  const ayudanteDistribuidor= async(valor) =>{
    setayuDistSelec(valor)
  }
  
  useEffect(() => {
    if (ayuDistSelec !== null) {
      async function fetchData() {
        const respuesta = await detallePedido(ayuDistSelec);
        if (respuesta){
          if (respuesta.SMListaErrores.length === 0) {
            setUltimosPedidos(respuesta)
            //const subTotalesTemp = respuesta.pedidos.
            //setSubTotales([...subTotales,prod.subtotalSolicitada])
            setCodPedido(respuesta.codPedido)
            //setAyudantes(respuesta.ayudanteDistribuidores);
          }else{
            const respuesta2 = await detallePedido(distribuidorSeleccionado);
            if(respuesta2){
              if(respuesta2.SMListaErrores.length ===0){
                setUltimosPedidos(respuesta2)
                setCodPedido(respuesta2.codPedido)
                setayuDistSelec(null)
              }
              else{
                setUltimosPedidos(null)
              }
            }
            
          }
        }
        
      }
      fetchData();
    }
  }, [ayuDistSelec]);

  const listaDistStyle={
    padding:"0",
    margin:"0",
    //backgroundColor: "green",
    boxSizing: "border-box",
    height: "40px",
    width: "100%",
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 5':'1 / 1 / 2 / 11',
  }
  const listaAyuDistStyle={
    padding:"0",
    margin:"0",
    //backgroundColor: "green",
    boxSizing: "border-box",
    height: "40px",
    width: "100%",
    gridArea: datos.esComputadora ? '1 / 6 / 2 / 11':'1 / 12 / 2 / 21',
  }

  const contenedorUltimosPedidos={
    gridArea: datos.esComputadora ? '3 / 1 / 19 / 21':'3 / 1 / 19 / 21',
    backgroundColor: "#F4F4F4",
    overflow: "auto",
    boxSizing: "border-box",
    marginTop: "10px",
    border: "1px solid #ccc",
    padding: "0 10px 0 10px",
    borderRadius: "10px",

  }
  const contenedorTotalesStyle={
    display: "grid",
    gridTemplateColumns:  "12vw repeat(19,1fr)",
    gridTemplateRows: "repeat(2,1fr)",//"100%",
    gridArea: datos.esComputadora ? '19 / 1 / 21 / 21':'19 / 1 / 21 / 21',
    backgroundColor: "#F4F4F4",
    //overflow: "auto",
    boxSizing: "border-box",
    marginTop: "10px",
    boxSizing: "border-box",
    border: "1px solid #ccc",
    borderRadius: "10px",
    textAlign: "center",
  }
  const cardPedidostyle={
    backgroundColor: "white",//"#FCFCFC",
    display: "inline-block",
    //clear: "both",
    borderRadius: "20px",
    margin: "20px 10px 0 0",
    //padding: "10px",
    overflow: datos.esComputadora ? "auto":"none",
    height: datos.esComputadora ? "250px":"",
    boxSizing: "border-box",
    width: datos.esComputadora ? "calc(100%/3.12)":"100%",
    border: "1px solid #ccc",
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",
  }
  const nomCatProdStyle={
    display: "block",
    backgroundColor: "#AD6726",
    color: "white",
    fontSize: datos.esComputadora ? "1vw":'1.7vh',
    //color: "#363636",
    fontWeight: "bolder",
    //marginBottom: "5px",
    textAlign: "Center",
    borderRadius: "10px 10px 0px 0px",
    padding: "5px",
  }
  const contProdStyle={
    padding: "0 10px 5px 10px ",
    backgroundColor: "#FFFFFF",
    borderRadius: "0px 0px 18px 18px",
   
  }
  const contCabProductoStyle={
    display: "grid",
    gridTemplateColumns: "30% repeat(4,1fr)",//"repeat(5,1fr)",
    gridTemplateRows: "100%",
    fontWeight: "800",
    //marginTop: "10px",
    //backgroundColor: "yellow",
    fontSize: datos.esComputadora ? "0.9vw":'1.6vh',
    color: "#868686",
    textAlign: "center",
  }
  const contProductoStyle={
    display: "grid",
    gridTemplateColumns:  "30% repeat(4,1fr)",
    gridTemplateRows: "100%",
    marginTop: "5px",
    //backgroundColor: "yellow",
    fontSize: datos.esComputadora ? "0.9vw":'1.6vh',
    color: "#868686",
  }
  
  const descProdStyle={
    gridArea: "1/1/1/2",
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",    
    textAlign: "left",
    //justifySelf: "center",
    alignItems: "center",
    color: "#737373",
    whiteSpace: "pre-wrap",
    overflow: "auto",
    fontWeight: "500",

  }
  const precProdStyle={
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",    
    textAlign: "center",
    justifySelf: "center",
    alignItems: "center",
    color: "#737373",
  }
  const ultPedProdStyle={
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",    
    textAlign: "center",
    justifySelf: "center",
    alignItems: "center",
    color: "#737373",
    
  }
  const pedProdStyle={
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",    
    textAlign: "center",
    justifySelf: "center",
    alignItems: "center",
    color: "#737373",
  }
  const subToPedStyle={
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",    
    textAlign: "center",
    justifySelf: "center",
    alignItems: "center",
    color: "#737373",
    fontWeight: "900",

  }
  const editarPedidoStyle={
    //backgroundColor: "Brown",
    gridArea: datos.esComputadora ? '1 / 11 / 2 / 13':'1 / 5 / 2 / 11',

    fontSize: "0.8em ",
    textAlign: "right",
    marginTop: datos.esComputadora ? "":"1vh",
    color: "#737373",
  }
  const guardarPedidoStyle={
    gridArea: datos.esComputadora ? '1 / 14 / 2 / 17':'1 / 10 / 2 / 13',
    fontSize: "0.8em ",
    textAlign: "right",
    marginTop: datos.esComputadora ? "":"1vh",
    color: "#737373",
  }
  const pdfStyle={
    gridArea: datos.esComputadora ? '1 / 17 / 2 / 20':'1 / 15 / 2 / 18',
    fontSize: "0.8em ",
    textAlign: "right",
    margin: "0",
    marginTop: datos.esComputadora ? "":"1vh",
    color: "#737373",
  }
  const labelTotPeStyle={
    display: "block",
    color: "#737373",
    fontWeight: "700",
  }
  const labelTotEnStyle={
    display: "block",
    color: "#737373",
    fontWeight: "700",
  }
  const contFecPedStyle={
    gridArea: datos.esComputadora ? '1 / 6 / 2 / 9':'1 / 9 / 2 / 15',
    padding:"0",
    margin:datos.esComputadora ? "0":"10px 0px 0px 0px",
    //backgroundColor: "green",
    position: datos.esComputadora ? "none":"relative",
    left: datos.esComputadora ? "none":"8%",
    boxSizing: "border-box",
    height: "40px",
    width: datos.esComputadora ? "100%":"100%",
  }
  const contFecRecStyle={
    //gridArea: datos.esComputadora ? '1 / 17 / 2 / 21':'2 / 12 / 2 / 21',}
    gridArea: datos.esComputadora ? '1 / 2 / 2 / 5':'1 / 2 / 2 / 9',

    padding:"0",
    margin: datos.esComputadora ? "0":"10px 0px 0px 0px",
    //backgroundColor: "green",
    position: datos.esComputadora ? "none":"none",
    left: datos.esComputadora ? "none":"8%",
    boxSizing: "border-box",
    height: "40px",
    width: datos.esComputadora ? "100%":"80%",
  }
  const inputFecStyle={
    height: "1px",
    padding: "15px 10px 5px",
    height: "100%",
    marginTop: "-5px",
    marginLeft: "-10%",
    width: "90%",
    borderRadius: "10px",
    border: "1px solid #ccc",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  }
  const labelFecStyle={
    fontSize: datos.esComputadora ? "0.9em":'1.6vh',
    marginLeft: "-10%",
  }
  const pedido={
    color:"red", 
    padding: "0", 
    margin: "0",
    width: "100%",
    fontSize: "1em",
    textAlign: "center",
    height: "2vh",
  }
  const totalStyle={
    display: "inline-block",
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 2':'1 / 1 / 2 / 2',
    marginRight: "10px",
    fontSize: datos.esComputadora ? "1.5vw":"1.8vh",
    fontWeight: "500",
    textAlign: "right",
  }
  const cantTotalStyle={
    display: "inline-block",
    gridArea: datos.esComputadora ? '1 / 2 / 2 / 3':'1 / 2 / 2 / 3',
    fontWeight: "500",
    fontSize: datos.esComputadora ? "2vw":"2vh",
    textAlign: "left",
  }
  const cambioFecPedido = event => {
    event.target.value =obtenerFechaActual()

  }
  const nomDistStyle={
    gridArea: datos.esComputadora ? '2 / 8 / 3 / 14':'2 / 5 / 3 / 17',
    textAlign: "center",
    fontSize: datos.esComputadora ? '2vw':"2.5vh",
    fontWeight: "500",
    marginTop: "10px",
  }
  const nroPedidoStyle={
    //gridArea: datos.esComputadora ? ' 1 / 18 / 2 / 21':' 1 / 18 / 2 / 21',
    gridArea: datos.esComputadora ? ' 2 / 18 / 3 / 21':' 2 / 18 / 3 / 21',
    fontSize: datos.esComputadora ? "1vw":"2.5vw",
    textAlign: "right"
  }
  const botonCerrarModalStyle={
    display: "flex",
    gridArea: datos.esComputadora ? ' 1 / 18 / 2 / 21':' 1 / 18 / 2 / 21',
    justifySelf: "right",
    alignItems: "center",
  }
  const cambioCantPedido = async(event) => {
    
    const respuesta = await actualizarEntrega(
        datos.codigoDistribuidor,
        parseInt(event.target.id),
        parseInt(datos.idPedido),
        isNaN(parseInt(event.target.value)) ? 0:parseInt(event.target.value)
    )
    if (respuesta){
      if(respuesta.SMListaErrores.length ===0){
        const subTotaltemp=document.getElementById(respuesta.codProducto+""+respuesta.codPedido)
        const totalTemp=document.getElementById("cantTotal")
        if(subTotaltemp !==null && totalTemp!==null){
          subTotaltemp.textContent = formatearMonedaSoles(respuesta.subtotalEntrega ? respuesta.subtotalEntrega: 0)
          totalTemp.textContent = formatearMonedaSoles(respuesta.totalEntrega ? respuesta.totalEntrega: 0)
          }
        }
    }
    
  }
  const cambioCantEntrega = async(event) => {
    console.log("entre a cambio de algo")
    const respuesta = 
       editarPedido ? 
        await actualizarPedido(
        datos.codigoDistribuidor,
        parseInt(event.target.id),
        parseInt(datos.idPedido),
        isNaN(parseInt(event.target.value)) ? 0:parseInt(event.target.value)
    ):
    editarEntrega ? 
    await actualizarEntrega(
      datos.codigoDistribuidor,
      parseInt(event.target.id),
      parseInt(datos.idPedido),
      isNaN(parseInt(event.target.value)) ? 0:parseInt(event.target.value)
  ):null

    if (respuesta){
      if(respuesta.SMListaErrores.length ===0){
        const subTotaltemp=document.getElementById(respuesta.codProducto+""+respuesta.codPedido)
        const totalTemp=document.getElementById("cantTotal")
        if(subTotaltemp !==null && totalTemp!==null){
          subTotaltemp.textContent = formatearMonedaSoles(respuesta.subtotalEntrega ? respuesta.subtotalEntrega: 0)
          totalTemp.textContent = formatearMonedaSoles(respuesta.totalEntrega ? respuesta.totalEntrega: 0)
          }
        }
    }  
  }

  const cambioFecRecojo= event =>{
    //onsole.log(event.target.value)
    setfecRecojo(event.target.value)
  }
  const solicitarPedidoClick = async event => {
    setestaProcesando(true)
    try{
      const respuesta = await entregar(
        datos.codigoDistribuidor,
        parseInt(datos.idPedido),
        fecRecojo+"T"+obtenerHoraActual(),
        2
      )
      if(respuesta){
        if(respuesta.SMListaErrores.length === 0){
          setestaProcesando(false)
          datos.cerrarModalClick();

        }else{
          setestaProcesando(false)
  
        }
      }
    }catch(error){
      setestaProcesando(false)
    }
    
  }
  const EditarPedidoClick=()=>{
    console.log("entre")
    seteditarPedido((prevState)=>!prevState)
  }
  const EditarEntregaClick=()=>{
    console.log("entre a editar entrega")
    setEditarEntrega((prevState)=>!prevState)
  }
  const pdfVisualizarClick = async event => {
    setestaProcesando(true)
    setVerPdf(true);
    try{
      //SE ACTUALIZA PARA EL PDF
    const respuesta2 = await detallePedidoGenerico(datos.idPedido)//detallePedido(datos.idPedido); // Esta función debe retornar el resultado que necesitas para la validación
        //const respuesta2 = await listarPedidos("",1,1);
    if(respuesta2){
      if(respuesta2.SMListaErrores.length ===0){
          setUltimosPedidos(respuesta2)
          setestaProcesando(false);
      }
      /*if(respuesta2.SMListaErrores.length ===0){
        setPedidos(respuesta2.Pedidos)
        //console.log(respuesta2)
      }*/
    }

      setestaProcesando(false);
      
    }catch(error){
      setestaProcesando(false)
    }
    
  }
  const cerrarModalClick = (event)=>{
    datos.cerrarModalClick();
  }
  const pdfStyleModal={
    borderRadius: "5px",
    display: "grid",
    gridTemplateColumns: "repeat(20,1fr)",
    gridTemplateRows: "repeat(20,1fr)",
    position: "absolute",
    left: "0px",
    top: "0px",
    width: "90vw",
    height: "90vh",
    backgroundColor: hexToRGBA("#323639",1),
    zIndex: "41", 
    boxSizing: "border-box"
  }
  const pdfContenidoStyle={
    gridArea : "1/1/21/21",
    backgroundColor: "red",
    position: "absolute",
    left: "0vw",
    top: "5vh",
    width: "90vw",
    height: "85vh",
    backgroundColor: hexToRGBA("#323639",0.8),
    zIndex: "42", 
    
  }
  const cerrarPdfModalClick=() =>{
    setVerPdf(false);
  }
  const styles = StyleSheet.create({
    page: {
      padding: 5,
      backgroundColor: 'white'
    },
    header: {
      fontSize: 20,
      marginBottom: 10,
      textAlign: 'center'
    },
    paragraph: {
      fontSize: 12,
      marginBottom: 10
    },
    columns: {
      flexDirection: 'row',
      marginBottom: 10
    },
    column: {
      flex: 1,
      padding: "0px"
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      flexDirection: 'row',
      height: "10px",
      paddingTop: "-1px"
    },
    tableCol: {
      width: '20%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCol2: {
      width: '40%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCell: {
      margin: 'auto',
      marginTop: 0,
      fontSize: 8,
    },
    tableColSpan: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCellLeft: {
      margin: 5,
      fontSize: 8,
      textAlign: 'left'
    },
    tableCellCenter: {
      margin: 5,
      fontSize: 8,
      textAlign: 'center'
    },
    tableCellRight: {
      margin: 5,
      fontSize: 8,
      textAlign: 'right'
    },
    tableCellHighlighted: { 
      margin: 0, 
      fontSize: 10, 
      backgroundColor: '#FCE5D7',
      padding: "0px"
    },
    tableCellHighlighted2: { 
      margin: 0, 
      fontSize: 10, 
      backgroundColor: '#F4AF85',
      padding: "0px"
    },
    tableCellHighlighted3: { 
      margin: 0, 
      fontSize: 10, 
      backgroundColor: 'white',
      padding: "0px"
    }
  });
  //#F4AF85
  return (
    <>
        <div id="contenedorFechaPedido" style={contFecPedStyle}>
            <Input
                id='fechaPedido'
                moderno='Fecha de Entrega'
                tipo='date'
                autoComplete
                estilos={inputFecStyle}
                estiloLabel={labelFecStyle}
                onChange={cambioFecPedido}
                valorInicial = {obtenerFechaActual()}
                deshabilitado
            />

        </div>
        <div id="contenedorFechaRecojo" style={contFecRecStyle}>
        <Input
                id='fechaPedido'
                moderno='Fecha Solicitada'
                tipo='date'
                autoComplete
                estilos={inputFecStyle}
                estiloLabel={labelFecStyle}
                onChange={cambioFecPedido}
                valorInicial = {convertirFechaISO8601(datos.fecSol)}
                deshabilitado
            />
        </div>
        <div style={nomDistStyle}>{datos.nombreDistribuidor}</div>
        <div style={nroPedidoStyle}>Nro. {String(datos.idPedido).padStart(8, '0')}</div>
        <div style={botonCerrarModalStyle}> 
            <Boton
                moderno='X'
                tipo='submit'
                color={hexToRGBA('#E12121', 1)}
                tonalidad='20'
                borde='5px'
                margenExterno={datos.esComputadora ? "0.6vw 0.2vw":'1vw 1vw 1vw 1vw'}
                margenInterno={datos.esComputadora ? "0.6vw 0.2vw":"1vw 2vw 1vw 2vw"}
                fontSize={datos.esComputadora ? "1vw":"1.6vh"}
                width = {datos.esComputadora ? "5vh":"8vw"}
                height = {datos.esComputadora ? "5vh":"8vw"}
                estaProcesando={estaProcesando}
                onClick={cerrarModalClick}
                labelStyle={{marginTop: datos.esComputadora ? "":""}}
              /> 
        </div>
      <div id= "contenedorUltimosPedidos" style={contenedorUltimosPedidos}>
        {ultimosPedidos!==null && ultimosPedidos.ProductosPedidosPorCategoria.map(pppc=>{
            return <div  key={pppc.codCategoria} id={pppc.descCategoria} style={cardPedidostyle}> 
                        <div style={nomCatProdStyle}> {pppc.descCategoria} </div>
                        <div style={contProdStyle}>
                          <div style={contCabProductoStyle}> 
                              <div>Producto</div>
                              <div>Precio x Unidad</div>
                              <div>Cantidad Solicitada</div>
                              <div>Entrega</div>
                              <div>Sub Total</div>
                          </div>
                          {pppc.productos.map(prod => {
                            return <div  key={prod.codProducto} style={contProductoStyle}> 
                                      <div style={descProdStyle}>{prod.descProducto}</div>
                                      <div style={precProdStyle}>{formatearMonedaSoles(prod.precioUnitario ? prod.precioUnitario: 0)}</div>
                                      <div style={ultPedProdStyle}>
                                        {editarPedido ? 
                                        <Input 
                                          antiguo=""//{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                                          tipo='number'
                                          estilos={pedido}
                                          onChange={(event)=>{
                                            if(event.target.value === ""){
                                              cambioCantEntrega(event)
                                            }
                                          }} 
                                          onBlur = {cambioCantEntrega}
                                          id={prod.codProducto}
                                          valorInicial = {prod.canSolicitada !==0 ? prod.canSolicitada:''} 
                                          estadoCambio = {codPedido !==0 ? codPedido:''}
                                          /> : prod.canSolicitada
                                        }
                                      </div>
                                      <div style={pedProdStyle}>
                                        {rol == "DESPACHADOR" ?
                                          <Input 
                                          antiguo=""//{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                                          tipo='number'
                                          estilos={{...pedido,borderColor: editarPedido ? "white":"#ccc"}}
                                          onChange={()=>{
                                          }} 
                                          onBlur = {cambioCantPedido}
                                          id={prod.codProducto}
                                          valorInicial = {prod.canEntregada !==0 ? prod.canEntregada:''} 
                                          estadoCambio = {codPedido !==0 ? codPedido:''}
                                          deshabilitado = {editarPedido}
                                          />
                                          
                                        :

                                        editarEntrega ? 
                                        <Input 
                                          antiguo=""//{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                                          tipo='number'
                                          estilos={pedido}
                                          onChange={(event)=>{
                                            if(event.target.value === ""){
                                              cambioCantEntrega(event)
                                            }
                                          }} 
                                          onBlur = {cambioCantEntrega}
                                          id={prod.codProducto}
                                          valorInicial = {prod.canEntregada !==0 ? prod.canEntregada:''} 
                                          estadoCambio = {codPedido !==0 ? codPedido:''}
                                          
                                          />: 
                                        <div id={prod.codProducto} style={subToPedStyle}>{prod.canEntregada ? prod.canEntregada: 0}</div>
                                      }
                                          
                                              
                                      </div>
                                      <div id={prod.codProducto+""+datos.idPedido} style={subToPedStyle}>{formatearMonedaSoles(prod.subtotalEntregada ? prod.subtotalEntregada: 0)}</div>
                                      
                                  </div>
                          })}
                        </div>
                        
                    </div>
                    
        })
          
        }
      </div>
      <div id="contenedorTotales" style={contenedorTotalesStyle}>
        <div id="total" style={totalStyle}>TOTAL:</div>
        <div id="cantTotal" style={cantTotalStyle}>{ultimosPedidos!==null && formatearMonedaSoles(ultimosPedidos.totalEntrega ? ultimosPedidos.totalEntrega:0)}</div>
        {ultimosPedidos !==null && ultimosPedidos.entregado === "N" &&
          <div id="editarPedido" style={editarPedidoStyle}> 
            {!editarEntrega && <Boton
                  moderno={!editarPedido ? 'Editar Pedido':'Finalizar edición'}
                  tipo='submit'
                  color={hexToRGBA('#DCAD2F', 1)}
                  tonalidad='20'
                  borde='5px'
                  margenExterno={datos.esComputadora ? "0.6vw 0.2vw":'0vw'}
                  margenInterno={datos.esComputadora ? "0.6vw 0.2vw":"1vw 2vw 1vw 2vw"}
                  fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                  width = {datos.esComputadora ? "10vw":"15vw"}
                  labelStyle = {{color: "black"}}
                  estaProcesando={estaProcesando}
                  onClick={EditarPedidoClick}
                />
            }
                
          </div>
        }
        {rol === "DESPACHADOR" &&
        <div id="guardarPedido" style={guardarPedidoStyle}> 
          { !editarPedido &&
            <Boton
                moderno='Terminar Entrega'
                tipo='submit'
                color={hexToRGBA('#2D8B0B', 1)}
                tonalidad='20'
                borde='5px'
                margenExterno={datos.esComputadora ? "0.6vw 0.2vw":'0vw'}
                margenInterno={datos.esComputadora ? "0.6vw 0.2vw":"1vw 2vw 1vw 2vw"}
                fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                width = {datos.esComputadora ? "10vw":"15vw"}
                estaProcesando={estaProcesando}
                onClick={solicitarPedidoClick}
              />
          }
          
        </div>
        }
        {ultimosPedidos !==null && rol === "SUPER ADMINISTRADOR" && !editarPedido &&
        <div id="guardarPedido" style={guardarPedidoStyle}>
            <Boton
                moderno= {editarEntrega ? 'Finalizar Edición':'Editar Entrega'}
                tipo='submit'
                color={hexToRGBA('#2D8B0B', 1)}
                tonalidad='20'
                borde='5px'
                margenExterno={datos.esComputadora ? "0.6vw 0.2vw":'0vw'}
                margenInterno={datos.esComputadora ? "0.6vw 0.2vw":"1vw 2vw 1vw 2vw"}
                fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                width = {datos.esComputadora ? "10vw":"15vw"}
                estaProcesando={estaProcesando}
                onClick={EditarEntregaClick}
              />
              </div>
        }
        {rol &&
        <div id="pdfStyle" style={pdfStyle}> 
          { !editarPedido && ultimosPedidos !==null && !editarEntrega &&
            <Boton
                moderno='PDF'
                tipo='submit'
                color={hexToRGBA('#8D0303', 1)}
                tonalidad='20'
                borde='5px'
                margenExterno={datos.esComputadora ? "0.6vw 0.2vw":'0vw'}
                margenInterno={datos.esComputadora ? "0.6vw 0.2vw":"1vw 2vw 1vw 2vw"}
                fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                width = {datos.esComputadora ? "10vw":"15vw"}
                height = {datos.esComputadora ? "":"100%"}
                estaProcesando={estaProcesando}
                onClick={pdfVisualizarClick}
                
            />
          }
        </div>
        }
        {
          verPdf && 
          <div id="pdfVista" style={pdfStyleModal}>
            <div style={botonCerrarModalStyle}> 
            <Boton
                moderno='X'
                tipo='submit'
                color={hexToRGBA('#E12121', 1)}
                tonalidad='20'
                borde='5px'
                margenExterno={datos.esComputadora ? "0vw":'0vw'}
                margenInterno={datos.esComputadora ? "0.6vw 0.2vw":"1vw 2vw 1vw 2vw"}
                fontSize={datos.esComputadora ? "1vw":"2vw"}
                width = {datos.esComputadora ? "5vh":"7vw"}
                height = {datos.esComputadora ? "5vh":"7vw"}
                estaProcesando={estaProcesando}
                onClick={cerrarPdfModalClick}
                labelStyle={{marginTop: datos.esComputadora ? "":"30%", fontSize: datos.esComputadora ? "2vh":"3vw"}}
              /> 
            </div>
            <div style ={pdfContenidoStyle}>
            <PDFViewer style={{width: "89.5vw", height: "85vh"}}>
              <Document>
              <Page size="A4" style={styles.page}>
                  <View>
                    <View style={styles.table}>
                    <View style={styles.tableRow}>
                          <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                          <View style={styles.tableCol2}><Text style={styles.tableCellHighlighted3}><Text style={styles.tableCellLeft}><Text style={styles.tableCell}>NOMBRE:</Text></Text></Text></View>
                          <View style={styles.tableColSpan}><Text style={styles.tableCellHighlighted3}><Text style={styles.tableCellLeft}><Text style={styles.tableCell}>{datos.nombreDistribuidor.toUpperCase()}</Text></Text></Text></View>
                      </View>
                      <View style={styles.tableRow}>
                          <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                          <View style={styles.tableCol2}><Text style={styles.tableCellHighlighted3}><Text style={styles.tableCellLeft}><Text style={styles.tableCell}>PEDIDO REALIZADO:</Text></Text></Text></View>
                          <View style={styles.tableColSpan}><Text style={styles.tableCellHighlighted3}><Text style={styles.tableCellLeft}><Text style={styles.tableCell}>{ultimosPedidos!==null && (ultimosPedidos.fechaEntregadaReal === null || ultimosPedidos.fechaEntregadaReal === "" || ultimosPedidos.fechaEntregadaReal === undefined) ? '':formatDateTime(ultimosPedidos.fechaEntregadaReal)}</Text></Text></Text></View>

                      </View>
                      <View style={styles.tableRow}>
                          <View style={styles.tableCol}><Text style={styles.tableCell}> </Text></View>
                          <View style={styles.tableCol2}><Text style={styles.tableCell}> </Text></View>
                          <View style={styles.tableCol}><Text style={styles.tableCell}> </Text></View>
                          <View style={styles.tableCol}><Text style={styles.tableCell}> </Text></View>
                      </View>

                      
                      <View style={styles.tableRow}>
                          <View style={styles.tableCol}><Text style={styles.tableCellHighlighted}><Text style={styles.tableCellCenter}>CANTIDAD</Text></Text></View>                           
                          <View style={styles.tableCol2}><Text style={styles.tableCellHighlighted}><Text style={styles.tableCellCenter}>PRODUCTOS</Text></Text></View>                           
                          <View style={styles.tableCol}><Text style={styles.tableCellHighlighted}><Text style={styles.tableCellCenter}>PRECIO UNITARIO</Text></Text></View>                           
                          <View style={styles.tableCol}><Text style={styles.tableCellHighlighted}><Text style={styles.tableCellCenter}>TOTAL</Text></Text></View>                           
                      </View>

                      {ultimosPedidos!==null && ultimosPedidos.ProductosPedidosPorCategoria.map(pppc=>{
                            return <div key={pppc.descCategoria}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol2}><Text style={styles.tableCellHighlighted2}><Text style={styles.tableCellCenter}>{pppc.descCategoria}</Text></Text></View>                           
                                        <View style={styles.tableCol}><Text style={styles.tableCellHighlighted2}><Text style={styles.tableCellCenter}> </Text></Text></View>                           
                                        <View style={styles.tableCol}><Text style={styles.tableCellHighlighted2}><Text style={styles.tableCellCenter}> </Text></Text></View>                           
                                    </View>
                                      {pppc.productos.map(prod => {
                                        return <View key={prod.descProducto} style={styles.tableRow}>
                                                  <View style={styles.tableCol}><Text style={styles.tableCell}>{prod.canEntregada !==0 ? prod.canEntregada:''}</Text></View>
                                                  <View style={styles.tableCol2}><Text style={styles.tableCellHighlighted3}><Text style={styles.tableCellLeft}>{prod.descProducto}</Text></Text></View>                           
                                                  <View style={styles.tableCol}><Text style={styles.tableCellHighlighted3}><Text style={styles.tableCellCenter}>{formatearMonedaSoles(prod.precioUnitario ? prod.precioUnitario: 0)}</Text></Text></View>                           
                                                  <View style={styles.tableCol}><Text style={styles.tableCellHighlighted3}><Text style={styles.tableCellCenter}>{formatearMonedaSoles(prod.subtotalEntregada ? prod.subtotalEntregada: 0)}</Text></Text></View>                           
                                              </View>
                                      })}    
                                      </div>      
                      })}
                      <View style={styles.tableRow}>
                          <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                          <View style={styles.tableCol2}><Text style={styles.tableCellHighlighted3}><Text style={styles.tableCellLeft}><Text style={styles.tableCell}></Text></Text></Text></View>
                          <View style={styles.tableCol}><Text style={styles.tableCellHighlighted2}><Text style={styles.tableCellCenter}>TOTAL:</Text></Text></View>                           
                          <View style={styles.tableCol}><Text style={styles.tableCellHighlighted2}><Text style={styles.tableCellCenter}>{ultimosPedidos!==null && formatearMonedaSoles(ultimosPedidos.totalEntrega ? ultimosPedidos.totalEntrega:0)}</Text></Text></View>                           
                        
                      </View>
                      
                    </View>
                  </View>
                </Page>
              </Document>
            </PDFViewer>
            </div>
          </div>
        }
      </div>
    </>
  )
}

function capitalizeWords(str) {
  let words = str.toLowerCase().split(' ');
  let capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(' ');
}

function formatDateTime(dateTimeStr) {
  // Expresión regular para extraer partes de la fecha y hora UTC
  const regexWithMilliseconds = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;
  const regexWithoutMilliseconds = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/;

  let match = dateTimeStr.match(regexWithMilliseconds);
  if (!match) {
    match = dateTimeStr.match(regexWithoutMilliseconds);
  }

  if (!match) {
    // Manejo de error si el formato de entrada no es válido
    console.error('Formato de fecha y hora no válido:', dateTimeStr);
    return null;
  }

  // Extraer partes de la fecha y hora UTC
  const [, year, month, day, hour, minute, second, millisecond] = match;

  // Convertir la hora de formato de 24 horas a formato de 12 horas con AM/PM
  let formattedHour = parseInt(hour, 10);
  const ampm = formattedHour >= 12 ? 'pm' : 'am';
  formattedHour = formattedHour % 12;
  formattedHour = formattedHour ? formattedHour : 12; // Hora '0' debería ser '12'

  // Formatear la hora en formato de 12 horas con AM/PM
  let formattedTime = `${formattedHour}:${minute}`;
  if (millisecond) {
    // Aquí reconocemos que hay milisegundos, pero no los mostramos
    formattedTime += ` ${ampm}`;
  } else {
    // Si no hay milisegundos, mostramos los segundos y AM/PM
    formattedTime += `:${second} ${ampm}`;
  }

  // Formatear la fecha en dd/mm/yyyy
  const formattedDate = `${day}/${month}/${year}`;

  // Salida final en el formato deseado
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
}

function obtenerHoraActual() {
  const ahora = new Date();
  const horas = ahora.getHours().toString().padStart(2, '0');
  const minutos = ahora.getMinutes().toString().padStart(2, '0');
  const segundos = ahora.getSeconds().toString().padStart(2, '0');
  return `${horas}:${minutos}:${segundos}`;
}
function obtenerFechaActual() {
  let fechaActual = new Date();
  let año = fechaActual.getFullYear();
  let mes = ('0' + (fechaActual.getMonth() + 1)).slice(-2); // Asegura 2 dígitos
  let dia = ('0' + fechaActual.getDate()).slice(-2); // Asegura 2 dígitos
  return `${año}-${mes}-${dia}`;
}
function formatearMonedaSoles(valor) {
  return valor.toLocaleString('es-PE', {
    style: 'currency',
    currency: 'PEN'
  });
}
function hexToRGBA (hex, opacidad) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}
function convertirFechaISO8601(fechaISO) {
    // Crear un objeto Date a partir de la cadena en formato ISO 8601
    const fecha = new Date(fechaISO);
  
    // Obtener los componentes de la fecha que deseamos mostrar
    const año = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Meses son indexados desde 0
    const dia = String(fecha.getDate()).padStart(2, '0');
  
    // Formar la fecha en el formato deseado
    const fechaFormateada = `${año}-${mes}-${dia}`;

    return fechaFormateada;
  }
export default DetallePedido
