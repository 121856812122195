import React, { useState, useEffect } from 'react'
import {
  actualizarEntrega,
  listarAyudantesDistribuidor,
  actualizarStock,
  obtenerCookie,
  listarPersonal,
  pedidoTienda,
  obtenerProductos,
  guardarStock
} from './../../../../ApiSanMiguel/Api'
import Opciones from './../../../../Elementos/Opciones'
import Input from './../../../../Elementos/Input'
import Boton from './../../../../Elementos/Boton'

function StockProductos (datos) {
  const [distribuidorSeleccionado, setDistribuidorSeleccionado] = useState(null)
  const [ayudantes, setAyudantes] = useState(null)
  const [ayuDistSelec, setayuDistSelec] = useState(null)
  const [fecRecojo, setfecRecojo] = useState(null)
  const [ultimosPedidos, setUltimosPedidos] = useState(null)
  const [codPedido, setCodPedido] = useState(null)
  const [subTotales, setSubTotales] = useState([])
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rol, setRol] = useState(
    obtenerCookie('descRol') !== null
      ? obtenerCookie('descRol').toUpperCase().trim()
      : ''
  )
  const [codUsuarioSesion, setcodUsuarioSesion] = useState(
    obtenerCookie('codUsuario') !== null
      ? obtenerCookie('codUsuario').toUpperCase().trim()
      : ''
  )
  const [reajustarStock, setreajustarStock] = useState(false)
  const [editarStock, seteditarStock] = useState(false)
  const [guardeCambios, setGuardeCambios] = useState(true)

  useEffect(() => {
    const listaDistribuidoresData = async () => {
      const respuesta2 = await obtenerProductos(codUsuarioSesion)

      if (respuesta2) {
        if (respuesta2.SMListaErrores.length === 0) {
          console.log(respuesta2)
          setUltimosPedidos(respuesta2)
        }
      }
    }
    listaDistribuidoresData()
  }, [])

  useEffect(() => {
    const listaDistribuidoresData = async () => {
      const respuesta2 = await obtenerProductos(codUsuarioSesion)

      if (respuesta2) {
        if (respuesta2.SMListaErrores.length === 0) {
          setUltimosPedidos(respuesta2)
        }
      }
    }
    listaDistribuidoresData()
  }, [estaProcesando])

  const distribuidorSeleccionar = async valor => {
    setDistribuidorSeleccionado(valor)
  }

  useEffect(() => {
    if (distribuidorSeleccionado !== null) {
      async function fetchData () {
        const respuesta = await listarAyudantesDistribuidor(
          distribuidorSeleccionado
        )
        const respuesta2 = await pedidoTienda(distribuidorSeleccionado)
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setAyudantes(respuesta.ayudanteDistribuidores)
          } else {
            setAyudantes(null)
          }
        }
        if (respuesta2) {
          if (respuesta2.SMListaErrores.length === 0) {
            setUltimosPedidos(respuesta2)
            setCodPedido(respuesta2.codPedido)
          } else {
            setUltimosPedidos(null)
            setayuDistSelec(null)
          }
        }
      }
      fetchData()
    } else {
      setayuDistSelec(null)
    }
  }, [distribuidorSeleccionado])

  const ayudanteDistribuidor = async valor => {
    setayuDistSelec(valor)
  }

  useEffect(() => {
    if (ayuDistSelec !== null) {
      async function fetchData () {
        const respuesta = await pedidoTienda(ayuDistSelec)
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setUltimosPedidos(respuesta)
            //const subTotalesTemp = respuesta.pedidos.
            //setSubTotales([...subTotales,prod.subtotalSolicitada])
            setCodPedido(respuesta.codPedido)
            //setAyudantes(respuesta.ayudanteDistribuidores);
          } else {
            const respuesta2 = await pedidoTienda(distribuidorSeleccionado)
            if (respuesta2) {
              if (respuesta2.SMListaErrores.length === 0) {
                setUltimosPedidos(respuesta2)
                setCodPedido(respuesta2.codPedido)
                setayuDistSelec(null)
              } else {
                setUltimosPedidos(null)
              }
            }
          }
        }
      }
      fetchData()
    }
  }, [ayuDistSelec])

  const botonReajStockStyle = {
    display:
      rol === 'SUPER ADMINISTRADOR'
        ? reajustarStock || editarStock
          ? 'none'
          : ''
        : 'none',
    padding: '0',
    margin: '0',
    //backgroundColor: "green",
    boxSizing: 'border-box',
    height: '40px',
    width: '100%',
    //    gridArea: datos.esComputadora ? '1 / 1 / 2 / 4':'1 / 1 / 2 / 10',
    gridArea: datos.esComputadora ? '1 / 5 / 2 / 8' : '1 / 11 / 2 / 15'
  }
  const botonEditarStockStyle = {
    display: reajustarStock || editarStock ? 'none' : '',
    padding: '0',
    margin: '0',
    //backgroundColor: "green",
    boxSizing: 'border-box',
    height: '40px',
    width: '100%',
    gridArea: datos.esComputadora ? '1 / 11 / 2 / 14' : '1 / 16 / 2 / 21'
  }

  const contenedorUltimosPedidos = {
    gridArea: datos.esComputadora ? '3 / 1 / 19 / 21' : '3 / 1 / 19 / 21',
    backgroundColor: '#F4F4F4',
    overflow: 'auto',
    boxSizing: 'border-box',
    marginTop: '10px',
    border: '1px solid #ccc',
    padding: '0 10px 0 10px',
    borderRadius: '10px'
  }
  const contenedorTotalesStyle = {
    display: 'grid',
    gridTemplateColumns: '50% repeat(4,1fr)',
    gridTemplateRows: '100%',
    gridArea: datos.esComputadora ? '19 / 1 / 21 / 21' : '19 / 1 / 21 / 21',
    backgroundColor: '#F4F4F4',
    //overflow: "auto",
    boxSizing: 'border-box',
    marginTop: '10px',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '10px',
    textAlign: 'center'
  }
  const cardPedidostyle = {
    backgroundColor: 'white', //"#FCFCFC",
    display: 'inline-block',
    //clear: "both",
    borderRadius: '20px',
    margin: '20px 10px 0 0',
    //padding: "10px",
    overflow: datos.esComputadora ? 'auto' : 'none',
    height: datos.esComputadora ? '250px' : '',
    boxSizing: 'border-box',
    width: datos.esComputadora ? 'calc(100%/2.12)' : '100%',
    border: '1px solid #ccc',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)'
  }
  const nomCatProdStyle = {
    display: 'block',
    backgroundColor: '#AD6726',
    color: 'white',
    fontSize: datos.esComputadora ? '1vw' : '1.7vh',
    //color: "#363636",
    fontWeight: 'bolder',
    //marginBottom: "5px",
    textAlign: 'Center',
    borderRadius: '10px 10px 0px 0px',
    padding: '5px'
  }
  const contProdStyle = {
    padding: '0 10px 5px 10px ',
    backgroundColor: '#FFFFFF',
    borderRadius: '0px 0px 18px 18px'
  }
  const contCabProductoStyle = {
    display: 'grid',
    gridTemplateColumns: '30% repeat(3,1fr)', //"repeat(5,1fr)",
    gridTemplateRows: '100%',
    fontWeight: '800',
    //marginTop: "10px",
    //backgroundColor: "yellow",
    fontSize: datos.esComputadora ? '0.9vw' : '1.6vh',
    color: '#868686',
    textAlign: 'center'
  }
  const contProductoStyle = {
    display: 'grid',
    gridTemplateColumns: '30% repeat(3,1fr)',
    gridTemplateRows: '100%',
    marginTop: '5px',
    //backgroundColor: "yellow",
    fontSize: datos.esComputadora ? '0.9vw' : '1.6vh',
    color: '#868686'
  }

  const descProdStyle = {
    gridArea: '1/1/1/2',
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'left',
    //justifySelf: "center",
    alignItems: 'center',
    color: '#737373',
    whiteSpace: 'pre-wrap',
    overflow: 'auto',
    fontWeight: '500'
  }
  const precProdStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const ultPedProdStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const pedProdStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373'
  }
  const subToPedStyle = {
    display: 'flex',
    paddingRight: '5px',
    boxSizing: 'border-box',
    textAlign: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    color: '#737373',
    fontWeight: '900'
  }
  const guardarPedidoStyle = {
    display: editarStock || reajustarStock ? '' : 'none',
    gridArea: datos.esComputadora ? '1 / 4 / 2 / 5' : '1 / 3 / 2 / 5',

    fontSize: '0.8em ',
    textAlign: 'right',
    marginRight: '10%',
    color: '#737373'
  }
  const labelTotPeStyle = {
    display: 'block',
    color: '#737373',
    fontWeight: '700'
  }
  const labelTotEnStyle = {
    display: 'block',
    color: '#737373',
    fontWeight: '700'
  }
  const contFecPedStyle = {
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 4' : '1 / 1 / 2 / 10',

    padding: '0',
    margin: datos.esComputadora ? '0px 0px 0px 10px' : '10px 0px 0px 0px',
    //backgroundColor: "green",
    position: datos.esComputadora ? 'none' : 'relative',
    left: datos.esComputadora ? 'none' : '8%',
    boxSizing: 'border-box',
    height: '40px',
    width: datos.esComputadora ? '100%' : '100%'
  }
  const contFecRecStyle = {
    display: rol === 'DESPACHADOR' ? 'none' : '',

    gridArea: datos.esComputadora ? '1 / 17 / 2 / 21' : '2 / 12 / 2 / 21',
    padding: '0',
    margin: datos.esComputadora ? '0' : '10px 0px 0px 0px',
    //backgroundColor: "green",
    position: datos.esComputadora ? 'none' : 'relative',
    left: datos.esComputadora ? 'none' : '8%',
    boxSizing: 'border-box',
    height: '40px',
    width: datos.esComputadora ? '100%' : '100%'
  }
  const inputFecStyle = {
    height: '1px',
    padding: '15px 10px 5px',
    height: '100%',
    marginTop: '-5px',
    marginLeft: '-10%',
    width: '90%',
    borderRadius: '10px',
    border: '1px solid #ccc',
    boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)'
  }
  const labelFecStyle = {
    fontSize: datos.esComputadora ? '0.9em' : '1.6vh',
    marginLeft: '-10%'
  }
  const pedido = {
    color: 'red',
    padding: '0',
    margin: '0',
    width: '100%',
    fontSize: '1em',
    textAlign: 'center'
    //height: "20px",
  }
  const totalStyle = {
    display: 'inline-block',
    gridArea: datos.esComputadora ? '1 / 1 / 2 / 2' : '1 / 1 / 2 / 2',
    marginRight: '10px',
    fontSize: datos.esComputadora ? '1.5vw' : '1.8vh',
    fontWeight: '500',
    textAlign: 'right'
  }
  const cantTotalStyle = {
    display: 'inline-block',
    gridArea: datos.esComputadora ? '1 / 2 / 2 / 3' : '1 / 2 / 2 / 3',
    fontWeight: '500',
    fontSize: datos.esComputadora ? '2vw' : '2vh',
    textAlign: 'left'
  }
  const cambioFecPedido = event => {
    event.target.value = obtenerFechaActual()
  }

  const cambioCantPedido = async event => {
    if (editarStock) {
      const respuesta = await actualizarStock(
        parseInt(event.target.id),
        isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value),
        parseInt(document.getElementById(event.target.id + 'sa').innerHTML),
        1
      )
      if (respuesta) {
        if (respuesta.SMListaErrores.length === 0) {
          const subTotaltemp = document.getElementById(
            respuesta.codProducto + 'A'
          )
          if(subTotaltemp)
            subTotaltemp.textContent = respuesta.stockDespues

          //const limpiarValor=document.getElementById(respuesta.codProducto)
          //limpiarValor.value=""
        }
      }
    } else {
      if (reajustarStock) {
        const respuesta = await actualizarStock(
          parseInt(event.target.id),
          isNaN(parseInt(event.target.value))
            ? 0
            : parseInt(event.target.value),
          parseInt(document.getElementById(event.target.id + 'sa').innerHTML),
          2
        )
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            const subTotaltemp = document.getElementById(
              respuesta.codProducto + 'A'
            )
            subTotaltemp.textContent = respuesta.stockDespues

            //const limpiarValor=document.getElementById(respuesta.codProducto)
            //limpiarValor.value=""
          }
        }
      }
    }
  }
  const cambioFecRecojo = event => {
    setfecRecojo(event.target.value)
  }
  const reajustarStockClick = async event => {
    setreajustarStock(true)
  }
  const editarStockClick = async event => {
    seteditarStock(true)
  }
  const guardarCambiosClick = async event => {
    if (editarStock) {
      setestaProcesando(true)
      //guardarStock
      const valoresAntiguos = document.querySelectorAll('.actualizadoantiguo')
      const stockAntiguo = document.querySelectorAll('.stockantiguo')

      /*for(let cant=0; cant<stockAntiguo.length; cant++){
            valoresAntiguos[cant].textContent=stockAntiguo[cant].innerHTML
        }*/
      const respuesta = await guardarStock()
      if (respuesta) {
        if (respuesta.SMListaErrores.length === 0) {
          setestaProcesando(false)
        }
      }
      seteditarStock(false)
      
      //window.location.reload()
      setGuardeCambios((prevValor)=>!prevValor);
    } else {
      if (reajustarStock) {
        setestaProcesando(true)

        const valoresAntiguos = document.querySelectorAll('.actualizadoantiguo')
        const stockAntiguo = document.querySelectorAll('.stockantiguo')

        /*for(let cant=0; cant<stockAntiguo.length; cant++){
              valoresAntiguos[cant].textContent=stockAntiguo[cant].innerHTML
          }*/

        setreajustarStock(false)
        setestaProcesando(false)
        //window.location.reload()
        setGuardeCambios((prevValor)=>!prevValor);
      }
    }
  }
  return (
    <>
      <div id='contenedorFechaPedido' style={contFecPedStyle}>
        <Input
          id='fechaPedido'
          moderno='Fecha Actual'
          tipo='date'
          autoComplete
          estilos={inputFecStyle}
          estiloLabel={labelFecStyle}
          onChange={cambioFecPedido}
          valorInicial={obtenerFechaActual()}
          deshabilitado
        />
      </div>

      <div id='contenedorListaDist' style={botonReajStockStyle}>
        <Boton
          moderno='REAJUSTAR STOCK'
          tipo='submit'
          color={hexToRGBA('#DCAD2F', 1)}
          tonalidad='20'
          borde='5px'
          margenExterno='5px 5px 5px 5px'
          margenInterno={datos.esComputadora ? '10px' : '5px 2px 5px 2px'}
          fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
          width={datos.esComputadora ? '100%' : '15vw'}
          estaProcesando={estaProcesando}
          onClick={reajustarStockClick}
          labelStyle={{
            color: 'black',
            fontWeight: '600',
            fontSize: datos.esComputadora ? '1vw' : '2vw',
            padding: datos.esComputadora ? '0.1vw' : '2vw'
          }}
        />
      </div>
      <div id='contenedorListaAyuDist' style={botonEditarStockStyle}>
        <Boton
          moderno='EDITAR STOCK'
          tipo='submit'
          color={hexToRGBA('#DCAD2F', 1)}
          tonalidad='20'
          borde='5px'
          margenExterno='5px 5px 5px 5px'
          margenInterno={datos.esComputadora ? '10px' : '5px 2px 5px 2px'}
          fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
          width={datos.esComputadora ? '100%' : '15vw'}
          estaProcesando={estaProcesando}
          onClick={editarStockClick}
          labelStyle={{
            color: 'black',
            fontWeight: '600',
            fontSize: datos.esComputadora ? '1vw' : '2vw',
            padding: datos.esComputadora ? '0.1vw' : '2vw'
          }}
        />
      </div>
      <div id='contenedorUltimosPedidos' style={contenedorUltimosPedidos}>
        {ultimosPedidos !== null &&
          ultimosPedidos.SMProductosPorCategoria.map(pppc => {
            return (
              <div
                key={pppc.codigoCategoria}
                id={pppc.categoria}
                style={cardPedidostyle}
              >
                <div style={nomCatProdStyle}> {pppc.categoria} </div>
                <div style={contProdStyle}>
                  <div style={contCabProductoStyle}>
                    <div>Producto</div>
                    <div>Precio x Unidad</div>
                    <div>Stock</div>
                    <div>Actualiza Stock</div>
                  </div>
                  {pppc.SMListaProductos.map(prod => {
                    return (
                      <div key={prod.codProducto} style={contProductoStyle}>
                        <div style={descProdStyle}>{prod.dscProducto}</div>
                        <div style={precProdStyle}>
                          {formatearMonedaSoles(
                            prod.precioUnitario ? prod.precioUnitario : 0
                          )}
                        </div>
                        <div
                          className='stockantiguo'
                          id={prod.codProducto + '' + 'A'}
                          style={pedProdStyle}
                        >
                          {' '}
                          {prod.stock}{' '}
                        </div>
                        <div style={pedProdStyle}>
                          <Input
                            antiguo='' //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                            tipo='number'
                            estilos={pedido}
                            onBlur={cambioCantPedido}
                            onChange={(event)=>{
                              if(event.target.value === ""){
                                cambioCantPedido(event);
                              }
                              
                            }}
                            id={prod.codProducto}
                            valorInicial={
                              !guardeCambios ? '':prod.registroStock !== 0 && prod.registroStock!==null ? prod.registroStock : ''
                              //prod.registroStock !== 0 ? prod.registroStock : ''
                            } 
                            deshabilitado={
                              editarStock || reajustarStock ? false : true
                            }
                            autoComplete
                            estadoCambio={guardeCambios}
                          />
                        </div>
                        <div
                          style={{
                            visibility: 'hidden',
                            fontSize: '0px',
                            width: '0',
                            height: '0'
                          }}
                          id={prod.codProducto + 'sa'}
                          className='actualizadoantiguo'
                        >
                          {prod.stock}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
      <div id='contenedorTotales' style={contenedorTotalesStyle}>
        <div id='guardarPedido' style={guardarPedidoStyle}>
          <Boton
            moderno='Guardar Cambios'
            tipo='submit'
            color={hexToRGBA('#2D8B0B', 1)}
            tonalidad='20'
            borde='5px'
            margenExterno='5px 5px 5px 5px'
            margenInterno={datos.esComputadora ? '10px' : '5px 2px 5px 2px'}
            fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
            width={datos.esComputadora ? '100%' : '100px'}
            estaProcesando={estaProcesando}
            onClick={guardarCambiosClick}
          />
        </div>
      </div>
    </>
  )
}

function capitalizeWords (str) {
  let words = str.toLowerCase().split(' ')
  let capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })
  return capitalizedWords.join(' ')
}

function formatDateTime (dateTimeStr) {
  const dateObj = new Date(dateTimeStr) // Crear un objeto Date desde la cadena

  const day = String(dateObj.getDate()).padStart(2, '0')
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const year = String(dateObj.getFullYear()).slice(-2) // Obtener los últimos dos dígitos del año

  const formattedDate = `${day}/${month}/${year}`
  let hours = dateObj.getHours()
  const minutes = String(dateObj.getMinutes()).padStart(2, '0')
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours = hours % 12
  hours = hours ? hours : 12 // '0' debería ser '12' en el formato de 12 horas

  const formattedTime = `${hours}:${minutes} ${ampm}`
  //${formattedTime}
  return `${formattedDate}`
}
function obtenerHoraActual () {
  const ahora = new Date()
  const horas = ahora.getHours().toString().padStart(2, '0')
  const minutos = ahora.getMinutes().toString().padStart(2, '0')
  const segundos = ahora.getSeconds().toString().padStart(2, '0')
  return `${horas}:${minutos}:${segundos}`
}
function obtenerFechaActual () {
  let fechaActual = new Date()
  let año = fechaActual.getFullYear()
  let mes = ('0' + (fechaActual.getMonth() + 1)).slice(-2) // Asegura 2 dígitos
  let dia = ('0' + fechaActual.getDate()).slice(-2) // Asegura 2 dígitos
  return `${año}-${mes}-${dia}`
}
function formatearMonedaSoles (valor) {
  return valor.toLocaleString('es-PE', {
    style: 'currency',
    currency: 'PEN'
  })
}
function hexToRGBA (hex, opacidad) {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}
export default StockProductos
