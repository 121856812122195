import React, { useState } from 'react'
import './Boton.css'

function tonoMenos (color, reduccion = 40) {
  // Extraer los componentes r, g, b y a del color de entrada
  const match = color.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/
  )
  if (!match) return color // Devolver el color original si el formato no es válido

  let [_, r, g, b, a] = match.map(Number) // Convertir los componentes a números
  if (isNaN(a)) a = 1 // Establecer opacidad predeterminada si no se especifica

  // Calcular la cantidad de reducción para cada componente de color
  // Ajusta esta cantidad según lo deseado
  // Aplicar la reducción a cada componente de color
  r = Math.max(0, r - reduccion) // Asegurar que el color no sea negativo
  g = Math.max(0, g - reduccion)
  b = Math.max(0, b - reduccion)

  // Devolver el nuevo color en formato rgba con la misma opacidad
  return `rgba(${r},${g},${b},${a})`
}

const Boton = datosBoton => {
  const [isHovered, setIsHovered] = useState(false)
  
  const buttonStyle = {
    backgroundColor: datosBoton.color,
    borderRadius: datosBoton.borde,
    padding: datosBoton.margenInterno ? datosBoton.margenInterno : '0px',
    fontSize: datosBoton.fontSize ? datosBoton.fontSize: '16px',
    border: datosBoton.estaProcesando ? 'none' : datosBoton.border ? datosBoton.border:'none', // Definir borde con todos sus atributos juntos '1px solid #878787'
    color: 'white',
    textDecoration: 'none',
    margin: datosBoton.margenExterno ? datosBoton.margenExterno: '0px',
    cursor: datosBoton.estaProcesando ? 'pointer' : 'pointer', // Cambiar el cursor cuando está deshabilitado
    transitionDuration: '0.1s',
    width: datosBoton.width ? datosBoton.width: '100%',
    height: datosBoton.height ? datosBoton.height: '',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    // Estilos para el hover
    ...(isHovered && { backgroundColor: tonoMenos(datosBoton.color, datosBoton.tonalidad) }),
    // Estilos cuando está deshabilitado
    ...(datosBoton.estaProcesando && { backgroundColor: '#EBEBE400', cursor: 'wait' , color: "#4c4c4ccc"}), // Cambiar el color del botón cuando está deshabilitado
  };
  return (
    <button
      style={{...buttonStyle,gridArea: datosBoton.ubicacion ? datosBoton.ubicacion: ""}}
      
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={!datosBoton.estaProcesando ? datosBoton.onClick : undefined} // Solo permite clics si no está cargando
      disabled={datosBoton.estaProcesando}
      className='custom-button'
      type = {datosBoton.tipo ? datosBoton.tipo:""}
      id = {datosBoton.id ? datosBoton.id : ""}
      
    >
      {datosBoton.estaProcesando ? <div className="spinner"></div> : <div style={datosBoton.labelStyle}>{datosBoton.moderno}</div>}
    </button>
  )
}

export default Boton
