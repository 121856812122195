import React, {useState, useEffect,useRef} from 'react'
import * as RD from 'react-dom/client'
import * as estilos from './AsistenciasCSS'
import Boton from './../../../../Elementos/Boton'
import AsistenciaPDF from './AsistenciaPDF'

import {
    formatDateTime,
    formatearFecha2,
    formatearFecha3,
    capitalizeWords,
    formatearMonedaSoles,
    asistencias,
    hexToRGBA,
    asistenciasDetalle
} from '../../../../ApiSanMiguel/Api'

let contadorID = 1;

const Asistencias = (datos)=> {

    const [asistenciasData, setAsistenciasData] = useState(null)
    const [asistenciaDetalleData, setAsistenciaDetData] = useState(null)
    const [paramDetalleAsistencia, setDA] = useState({})
    const [asistenciaGlobal, setAsistenciaGlobal] = useState(true);
    const [asistenciaDetalle, setAsistenciaDetalle] = useState(false);
    const claveContadorRef = useRef(0)
    const [verPdf, setVerPdf] = useState(false)
    const [rangoFechas, setrangoFechas] = useState(null)
    const [montoDelDetalle, setMontoTotalDelDetalle] = useState("S/. 0.0")
    useEffect(
        () =>{
            const asistencia = async ()=>{
                const respuesta = await asistencias();
                if(respuesta){
                    console.log(respuesta);
                    setAsistenciasData(respuesta.reportes);
                }
            }
            asistencia();
        },[])
    
    const clickRegistroAsistencia = async (e) =>{
        setMontoTotalDelDetalle(e.currentTarget.children[e.currentTarget.children.length - 1].textContent)
        setAsistenciaDetalle(true);
        setAsistenciaGlobal(false);
        const respuesta = await asistenciasDetalle(e.currentTarget.id);
        if(respuesta){
            console.log(respuesta.fechas);
            setrangoFechas(respuesta.fechas)

            respuesta.reportes.forEach(reporte =>{
                reporte.asistencias.forEach(
                    (asistencia,index) =>{
                        if(asistencia === null){
                            reporte.asistencias[index] = {
                                id: "UUID"+contadorID++,//(Math.floor(Math.random()* (200 - 1 + 1)) + 1), 
                                dias : null,
                                fecha_pago:null,
                                horas_extras:null,
                                horas_trabajadas:null,
                                ingreso:null,
                                ingreso_almuerzo: null,
                                monto_pago:null,
                                pagado:null,
                                salida:null,
                                salida_almuerzo:null
                            }
                        }
                    }
                )
            })
            console.log(respuesta)
            setAsistenciaDetData(respuesta.reportes)
        }
    }

    const cerrarPdfModalClick=() =>{
        console.log("entre")
        setVerPdf(false);
    }

    return <>
            {verPdf && 
            <AsistenciaPDF 
                esComputadora={datos.esComputadora} 
                cerrarPdfModalClick={cerrarPdfModalClick} 
                verpdf={verPdf} 
                rangoFechas = {rangoFechas}
                asistenciaDetalleData = {asistenciaDetalleData}
            />
            }
            {asistenciaDetalle && <div style={estilos.footerOpcionesCSS(datos.esComputadora)}>
                <div style={estilos.asistenciPDfCSS(datos.esComputadora)}>
                    <Boton
                        moderno='PDF'
                        tipo='submit'
                        color={hexToRGBA('#8D0303', 1)}
                        tonalidad='20'
                        borde='5px'
                        fontSize={datos.esComputadora ? "1vw":"2.5vw"}

                        //margenExterno='5px 5px 5px 5px'
                        margenInterno='10px'
                        estaProcesando={false}
                        onClick={()=>{setVerPdf(true);console.log(verPdf)}}
                    />
                </div>
                {asistenciaDetalle &&
                <div style={estilos.RegresarCSS(datos.esComputadora)}>
                    <Boton
                        moderno='Volver'
                        tipo='submit'
                        color={hexToRGBA('#ecab00', 1)}
                        tonalidad='20'
                        borde='5px'
                        fontSize={datos.esComputadora ? "1vw":"2.5vw"}
                        //margenExterno='5px 5px 5px 5px'
                        margenInterno='10px'
                        estaProcesando={false}
                        onClick={()=>{setAsistenciaDetalle(false);setAsistenciaGlobal(true)}}
                    />
                </div>
                }
                <div style={estilos.MontoTotalDetalle(datos.esComputadora)}>
                    <div style={{justifyContent: "flex-start"}}>Total Semanal</div> 
                    <div style={{justifyContent: "flex-end"}}>{montoDelDetalle}</div>
                </div>
            </div>
            }
            <div id="contenedorAsistencias" style={estilos.contenedorAsistenciasCSS(datos.esComputadora,asistenciaDetalle)}>
                <div id="contenidoAsistenciasGlobal" style={estilos.contenidoAsistenciasGlobalCSS(datos.esComputadora)}>
                    <div id="contenido" style={estilos.contenidoCSS(datos.esComputadora)}>
                        <div id="contenido2" style={estilos.contenidoRegistroAsistenciaCSS(datos.esComputadora)}>
                            {asistenciaGlobal && <>
                                <div style={estilos.registroAsistenciaTituloCSS(datos.esComputadora)}>
                                    <div id="t1">
                                        Inicio de Registro
                                    </div>
                                    <div id="t2">
                                        Fin de Registro
                                    </div>
                                    <div id="t3">
                                        Pago Conglomerado
                                    </div>
                                </div>
                                
                                {
                                    asistenciasData!==null && asistenciasData.map((asistencia)=>(
                                        <div 
                                            id={asistencia.id} 
                                            key={"asistencia"+asistencia.id} 
                                            style={estilos.registroAsistenciaCSS(datos.esComputadora)}
                                            onClick = {clickRegistroAsistencia}
                                        >
                                            <div id="td1">
                                                {asistencia.fecha_inicio ? formatearFecha2(asistencia.fecha_inicio):''}
                                            </div>
                                            <div id="td2">
                                                {asistencia.fecha_fin ? formatearFecha2(asistencia.fecha_fin):''}
                                            </div>
                                            <div id="td3">
                                                {asistencia.monto ? formatearMonedaSoles(asistencia.monto) : ""}
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                            }

                            {asistenciaDetalle && 
                            <>
                                <div style={estilos.registroAsistenciaDetalleTituloCSS(datos.esComputadora)}>
                                    <div style={{gridArea:"1/1/2/3"}}>Datos</div>
                                    <div style={{gridArea:"1/3/2/4"}}>Lunes <br/> {rangoFechas!==null ? formatearFecha2(rangoFechas[0]):""}</div>
                                    <div style={{gridArea:"1/4/2/5"}}>Martes <br/> {rangoFechas!==null ? formatearFecha2(rangoFechas[1]):""}</div>
                                    <div style={{gridArea:"1/5/2/6"}}>Miercoles <br/> {rangoFechas!==null ? formatearFecha2(rangoFechas[2]):""}</div>
                                    <div style={{gridArea:"1/6/2/7"}}>Jueves <br/> {rangoFechas!==null ? formatearFecha2(rangoFechas[3]):""}</div>
                                    <div style={{gridArea:"1/7/2/8"}}>Viernes <br/> {rangoFechas!==null ? formatearFecha2(rangoFechas[4]):""}</div>
                                    <div style={{gridArea:"1/8/2/9"}}>Sábado <br/> {rangoFechas!==null ? formatearFecha2(rangoFechas[5]):""}</div>
                                    <div style={{gridArea:"1/9/2/10"}}>Domingo <br/> {rangoFechas!==null ? formatearFecha2(rangoFechas[6]):""}</div>
                                </div>
                                
                                {asistenciaDetalleData!==null && asistenciaDetalleData.map((asistenciaDetalleElemento)=>(
                                        <div 
                                            id={asistenciaDetalleElemento.usuario}
                                            key={asistenciaDetalleElemento.usuario} 
                                            style={estilos.registroAsistenciaDetalleCSS(datos.esComputadora)}
                                            onClick = {()=>{}}
                                        >
                                            
                                            <div style={{gridArea:"1/1/4/2",...estilos.datosUsuarioCSS(datos.esComputadora)}}>
                                                {capitalizeWords(asistenciaDetalleElemento.persona.nombre+' '+
                                                    asistenciaDetalleElemento.persona.apellido_paterno+' ' +
                                                    asistenciaDetalleElemento.persona.apellido_materno+' '  
                                                )}
                                            </div>
                                            <div>
                                                <div style={{gridArea:"1/2/2/3",...estilos.registroDescCSS(datos.esComputadora,"ingreso")}}>hora ingreso</div>
                                                <div style={{gridArea:"2/2/3/3",...estilos.registroDescCSS(datos.esComputadora,"salida")}}>hora salida</div>
                                                <div style={{gridArea:"3/2/4/3",...estilos.registroDescCSS(datos.esComputadora,"pago")}}>pago</div>
                                            </div>
                                            
                                            {asistenciaDetalleElemento.asistencias.map((asistenciaDiarias)=>(
                                                <div key={asistenciaDiarias.id}>
                                                    <div style={{gridArea:"1/3/2/4",...estilos.registroDetCSS(datos.esComputadora,"ingreso")}}>
                                                        {asistenciaDiarias.ingreso ?  formatearFecha3(asistenciaDiarias.ingreso) :'-'}
                                                    </div>
                                                    <div style={{gridArea:"2/3/3/4",...estilos.registroDetCSS(datos.esComputadora, "salida")}}>
                                                        {asistenciaDiarias.salida ? formatearFecha3(asistenciaDiarias.salida) :'-'}
                                                    </div>
                                                    <div style={{gridArea:"3/2/4/3",...estilos.registroDetCSS(datos.esComputadora, "pago")}}>
                                                        {asistenciaDiarias.monto_pago ? formatearMonedaSoles(asistenciaDiarias.monto_pago) :'-'}
                                                    </div>
                                                   
                                                </div>
                                               
                                            ))}
                                            <div style={{gridArea: "2/2/3/3",...estilos.registroDescCSS(datos.esComputadora,"pagoTotal")}}>
                                                Pago Total
                                            </div>
                                            <div style={{gridArea: "2/3/3/10",...estilos.registroDetCSS(datos.esComputadora, "pagoTotal")}}>
                                                {asistenciaDetalleElemento.pago ? formatearMonedaSoles(asistenciaDetalleElemento.pago) :'-'}
                                            </div>
                                        </div>
                                    ))}
                                </>
                                }   
                            </div>
                        </div>
                    </div>
                    
                </div>
                
        </>
}
export default Asistencias;