import React, { useState, useEffect, useRef } from 'react'
import * as estilos from './ModalGenerarPagoPedidoCSS'
import Boton from '../../../../Elementos/Boton'
import Input from '../../../../Elementos/Input'
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer
} from '@react-pdf/renderer'

import { 
  hexToRGBA,
  formatearFecha2,
  capitalizeWords,
  formatearFecha3,
  formatearMonedaSoles,
  registroPago
 } from '../../../../ApiSanMiguel/Api'

function ModalGenerarPagoPedido (datos) {
  //const [verPdf2, setVerPdf] = useState(datos.verpdf)
  const [estaProcesando, setestaProcesando] = useState(false)
  const [rangoFechas, setrangoFechas] = useState(datos.rangoFechas)
  const [asistenciaDetalleData, setAsistenciaDetData] = useState(datos.asistenciaDetalleData)
  const [montoPagar,setMontoPagar] = useState('')
  const [fechaDePago, setFechaDePago] = useState('')
  const [envioForm, setEnvioForm] = useState(false)

  useEffect(
    ()=>{
      console.log(asistenciaDetalleData)
    }, []
  )

  return (
    <>
      {console.log(datos.esComputadora)}
      <div
        id='baseModal'
        style={estilos.baseModal(datos.esComputadora)}
      >
        {datos.mostrarModal && (
          <div id='contenedorModal' style={estilos.contenedorModal(datos.esComputadora)}>
            <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}>
              <Boton
                moderno='X'
                tipo='submit'
                color={hexToRGBA('#E12121', 1)}
                tonalidad='20'
                borde='5px 10px 5px'
                margenExterno={datos.esComputadora ? '0vw' : '0vw'}
                margenInterno={
                  datos.esComputadora ? '0.6vw 0.2vw' : '1vw 2vw 1vw 2vw'
                }
                fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
                width={datos.esComputadora ? '5vh' : '7vw'}
                height={datos.esComputadora ? '5vh' : '7vw'}
                estaProcesando={estaProcesando}
                onClick={datos.cerrarPdfModalClick}
                labelStyle={{
                  marginTop: datos.esComputadora ? '' : '30%',
                  fontSize: datos.esComputadora ? '2vh' : '3vw'
                }}
              />
            </div>
            <div style={estilos.tituloCSS(datos.esComputadora)}>
              Generar Pago
            </div>
            <div id="contenidoModal" style={estilos.contenidoModal(datos.esComputadora)}>
              <div style={estilos.formularioCSS(datos.esComputadora)}>
                <Input
                  moderno='Monto a Pagar' //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                  tipo='number'
                  ubicacion = "1/2/2/11" 
                  estilos={estilos.estiloVacio(datos.esComputadora,envioForm,montoPagar)}
                  onBlur={()=>{}}
                  onChange={(event)=>{
                    setMontoPagar(event.currentTarget.value)
                   
                  }}
                  id={'montoPago'}
                  valorInicial={
                    null
                    //guardeCambios ? '':prod.registroStock !== 0 && prod.registroStock!==null ? prod.registroStock : ''
                    //prod.registroStock !== 0 ? prod.registroStock : ''
                  } 
                  maxDigitos="8"
                  estiloLabel={{fontSize: datos.esComputadora ? "1vw":"2.5vw"}}
                />
                <Input
                  moderno='Fecha de Pago' //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                  tipo='date'
                  ubicacion = "1/11/2/20" 
                  estilos={estilos.estiloVacio(datos.esComputadora,envioForm,fechaDePago)}
                  onBlur={()=>{}}
                  onChange={(event)=>{
                    setFechaDePago(event.currentTarget.value)
                  }}
                  id={'montoPago'}
                  valorInicial={
                    null
                    //guardeCambios ? '':prod.registroStock !== 0 && prod.registroStock!==null ? prod.registroStock : ''
                    //prod.registroStock !== 0 ? prod.registroStock : ''
                  } 
                  estiloLabel={{fontSize: datos.esComputadora ? "1vw":"2.5vw"}}

                />
                <Boton
                  moderno='Confirmar'
                  ubicacion = "2/2/3/10"
                  tipo='submit'
                  color={hexToRGBA('#2D8B0B', 1)}
                  tonalidad='20'
                  borde='5px'
                  margenExterno='5px 5px 5px 5px'
                  margenInterno={datos.esComputadora ? '10px' : '15px 2px 15px 2px'}
                  fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
                  width={datos.esComputadora ? '100%' : '100%'}
                  estaProcesando={estaProcesando}
                  onClick={async()=>{
                    setEnvioForm(true)
                    if(montoPagar!== '' && fechaDePago !==''){
                      setestaProcesando(true)
                      const respuesta = await registroPago(
                        datos.codigoPedidoEliminar,
                        'S',
                        '',
                        '',
                        '',
                        parseInt(montoPagar),
                        fechaDePago
                      )
                      if(respuesta){
                        console.log(respuesta)
                        if(respuesta.SMListaErrores.length === 0){
                          datos.cerrarPdfModalClick()
                        }
                      }
                      setestaProcesando(false)
                    }
                    
                  }}
                />
                <Boton
                  moderno='Cancelar'
                  ubicacion = "2/12/3/20"
                  tipo='submit'
                  color={hexToRGBA('#F52424', 1)}
                  tonalidad='20'
                  borde='5px'
                  margenExterno='5px 5px 5px 5px'
                  margenInterno={datos.esComputadora ? '10px' : '15px 2px 15px 2px'}
                  fontSize={datos.esComputadora ? '1vw' : '1.6vh'}
                  width={datos.esComputadora ? '100%' : '100%'}
                  estaProcesando={estaProcesando}
                  onClick={datos.cerrarPdfModalClick}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ModalGenerarPagoPedido
